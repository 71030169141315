import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Container from 'src/components/UI/Grid/Container'
import Image from 'src/components/UI/Image/Image'
import Components from 'src/storyblok/Components'
import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './Interview.module.scss'
import { Props } from './types'

const Interview = ({ blok }: Props) => {
  const { title, subtitle, questions, image } = blok

  return (
    <SbEditable content={blok}>
      <Flex width={1} flexDirection={['column', null, null, 'row']}>
        <Box
          order={[2, null, null, 1]}
          className={styles.content}
          paddingTop={[12, null, null, 25]}
          paddingBottom={[20, null, null, 20]}
          width={[1, null, null, 1 / 2]}
        >
          <Container>
            {title && (
              <Box
                className={styles.title}
                as="h2"
                marginBottom={[6, null, null, 8]}
              >
                {title}
              </Box>
            )}
            {subtitle && (
              <Box marginBottom={[8, null, null, 8]}>
                <Markdown className={styles.subtitle} source={subtitle} />
              </Box>
            )}
            {questions &&
              questions.map((blok: any, index: number) => {
                const { question, answer } = blok
                return (
                  <Row
                    key={blok?._uid}
                    className={styles.question}
                    marginBottom={
                      index !== questions?.length - 1 ? [8, null, null, 10] : 0
                    }
                    flexDirection="column"
                  >
                    <Column width={[4 / 4, null, null, 6 / 6]}>
                      {question && (
                        <Box marginBottom={[3, null, null, 2]}>{question}</Box>
                      )}
                    </Column>
                    <Column
                      width={[3 / 4, null, null, 5 / 6]}
                      marginLeft={[
                        (1 / 4) * 100 + '%',
                        null,
                        null,
                        (1 / 6) * 100 + '%',
                      ]}
                    >
                      {answer && <Markdown source={answer} />}
                    </Column>
                  </Row>
                )
              })}
          </Container>
        </Box>
        <Box
          order={[1, null, null, 2]}
          style={{ position: 'relative' }}
          width={[1, null, null, 1 / 2]}
          className={styles.imageWrapper}
        >
          {image && image.filename && (
            <Image
              className={styles.image}
              src={image.filename}
              size={'50vw'}
              alt={image.title}
            />
          )}
        </Box>
      </Flex>
    </SbEditable>
  )
}

export default Interview
