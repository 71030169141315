import React from 'react'
import { Box, Flex } from 'reflexbox'
import isNode from 'is-node'

import { Props } from './types'
import * as styles from './VideoComponent.module.scss'

class Video extends React.Component {
  videoRef = null

  constructor(props: Props) {
    super(props)
    if (!isNode) {
      this.observer = new IntersectionObserver(this.observeHandler, {
        rootMargin: '0px 0px 200px 0px',
      })
    }
  }

  playVideo() {
    if (this && this.videoRef) {
      setTimeout(() => {
        this.videoRef.play()
      }, 300)
    }
  }
  observeHandler = (entries: any[]) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        if (this.videoRef) {
          console.log('Play video')
          this.playVideo()

          // Debugging
          //   var videoPromise = this.videoRef.play()
          //   if (videoPromise !== undefined) {
          //     videoPromise
          //       .then(_ => {
          //         console.log("Autoplay started!")
          //       })
          //       .catch(error => {
          //         console.log("Autoplay was prevented.")
          //         console.log("error", error)
          //       })
          //   }
        }
        this.observer.unobserve(entry.target)
      }
    }
  }

  render() {
    const { src, ratio }: Props = this.props || {}
    const ext = src ? src.substr(src.lastIndexOf('.') + 1) : null
    if (src && ext && ext === 'mp4') {
      return ratio ? (
        <Box className={styles.wrapper}>
          <video
            muted
            loop
            playsInline
            autoPlay
            ref={(x) => {
              if (x) {
                this.observer.observe(x)
                this.videoRef = x
              }
            }}
          >
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      ) : (
        <video
          muted
          loop
          playsInline
          autoPlay
          ref={(x) => {
            if (x) {
              this.observer.observe(x)
              this.videoRef = x
            }
          }}
        >
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    } else {
      return (
        <Flex className={styles.placeholder}>
          <Flex justifyContent="center" alignItems="center">
            Upload mp4 video format
          </Flex>
        </Flex>
      )
    }
  }
}

export default Video
