import React from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Container from 'src/components/UI/Grid/Container'
import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './Highlight.module.scss'
import { Props } from './types'

const Highlight = ({
  blok,
  color = '#d4cc33',
  textColor = '#000000',
}: Props) => {
  const { title, text } = blok

  return (
    <SbEditable content={blok}>
      <Box bg={color} width={1} className={styles.wrapper}>
        <Container
          paddingY={[6, null, null, 12]}
          flexDirection="column"
          justifyContent="space-between"
          className={styles.content}
        >
          {title && (
            <Box className={styles.title} color={textColor}>
              {title}
            </Box>
          )}

          {text && (
            <Box className={styles.text} color={textColor} as="span">
              <Markdown source={text} />
            </Box>
          )}
        </Container>
      </Box>
    </SbEditable>
  )
}

export default Highlight
