// extracted by mini-css-extract-plugin
export var button = "PageInvestmentLanding-module--button--a-vqw";
export var closedItem = "PageInvestmentLanding-module--closedItem--Ot9Yq";
export var closedItems = "PageInvestmentLanding-module--closedItems--E0uwV";
export var imageWrapper = "PageInvestmentLanding-module--imageWrapper--pG5tP";
export var listDescription = "PageInvestmentLanding-module--listDescription--FnsQs";
export var listItem = "PageInvestmentLanding-module--listItem--V4SmS";
export var listTitle = "PageInvestmentLanding-module--listTitle--pnNWO";
export var listYear = "PageInvestmentLanding-module--listYear--+P2aY";
export var preamble = "PageInvestmentLanding-module--preamble--Ivo5F";
export var readMore = "PageInvestmentLanding-module--readMore--M2l6l";
export var sectionNavigation = "PageInvestmentLanding-module--sectionNavigation--hFU75";
export var table = "PageInvestmentLanding-module--table--RhGQu";
export var title = "PageInvestmentLanding-module--title--PQma7";