import React from 'react'

import * as styles from './Label.module.scss'

type Props = {
  htmlFor: string
  children: any
  className?: string
  id?: string
}

const Label = ({ htmlFor, children, className = '', ...rest }: Props) => {
  return (
    <label
      className={[styles.label, className].join(' ')}
      htmlFor={htmlFor}
      {...rest}
    >
      {children}
    </label>
  )
}

export default Label
