import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'
import Components from 'src/storyblok/Components'

import * as styles from './Table.module.scss'
import { Props } from './types'

const Table = ({ blok }: Props) => {
  const { table, description } = blok
  const { tbody, thead }: any = table ? table : {}

  return (
    <SbEditable content={blok}>
      <Flex marginBottom={[8, null, null, 12]} flexDirection="column" width={1}>
        <table className={styles.table}>
          <tbody>
            {thead && (
              <tr>
                {thead.map((head: any) => (
                  <th key={head._uid}>{head.value}</th>
                ))}
              </tr>
            )}
            {tbody &&
              tbody.map((tb: any) => (
                <tr key={tb._uid}>
                  {tb.body &&
                    tb.body.map((inner: any) => (
                      <td key={inner._uid}>{inner.value}</td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
        {description && (
          <Box marginTop={[6, null, null, 3]} className={styles.description}>
            <Markdown source={description} />
          </Box>
        )}
      </Flex>
    </SbEditable>
  )
}

export default Table
