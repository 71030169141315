import React, { useEffect, useRef, useState } from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'

import theme from 'src/theme/mapTheme.json'

import { Props } from './types'

const googleMapsKey = process.env.GATSBY_GOOGLE_MAPS_KEY
const MapWithAMarker = withScriptjs(
  withGoogleMap((props: any) => {
    const google = window.google
    const mapRef = useRef(null)
    const { markers, onClick } = props
    const [active, setActive] = useState(markers[0].id)

    // Fit bounds on mount, and when the markers change
    useEffect(() => {
      //   if (first.id === center.id) {
      //   }
      const bounds = new window.google.maps.LatLngBounds()
      markers.forEach((marker) => {
        bounds.extend(
          new google.maps.LatLng({
            lat: parseFloat(marker.lat),
            lng: parseFloat(marker.lng),
          })
        )
      })
      if (mapRef?.current) {
        mapRef.current.fitBounds(bounds)
      }
    }, [google.maps.LatLng, markers])

    return (
      <GoogleMap
        defaultOptions={{
          styles: theme,
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
        }}
        ref={mapRef}
        // center={{
        //   lat: parseFloat(center[0].lat),
        //   lng: parseFloat(center[0].lng),
        // }}
        defaultCenter={{
          lat: parseFloat(markers[0].lat),
          lng: parseFloat(markers[0].lng),
        }}
      >
        {markers?.length > 0 &&
          markers.map((marker: any) => {
            const fillColor = marker.id === active ? '#d4cc33' : '#213f39'
            const opacity = marker.id === active ? 1 : 0.8
            const zIndex = marker.id === active ? 2 : 1

            const icon = {
              path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
              fillColor: fillColor,
              fillOpacity: opacity,
              strokeWeight: 0,
              scale: 0.6,
            }
            return (
              <Marker
                key={marker.lat}
                position={{
                  lat: parseFloat(marker.lat),
                  lng: parseFloat(marker.lng),
                }}
                zIndex={zIndex}
                icon={icon}
                onClick={() => {
                  onClick(marker.id)
                  setActive(marker.id)
                }}
              />
            )
          })}
      </GoogleMap>
    )
  })
)

const EstatesMap = ({ markers, onClick }: Props) => {
  return (
    <MapWithAMarker
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: '100%', width: '100%' }} />}
      mapElement={<div style={{ height: `100%` }} />}
      markers={markers}
      onClick={onClick}
    />
  )
}

export default EstatesMap
