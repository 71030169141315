import * as React from 'react'
import { useState } from 'react'
import { Flex, Box } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import PostListItem from 'src/components/RecentPosts/PostList/PostListItem/PostListItem'

import * as styles from './PostList.module.scss'
import { Props } from './types'

const PostList = ({ title, posts, url, linkType }: Props) => {
  const { t } = useTranslation()

  const [showAll, setShowAll] = useState(false)
  const showMore = !url && posts.length > 2

  return (
    <Column marginBottom={[11, null, null, 14]} width={[1, null, null, 8 / 12]}>
      <Row>
        <Column
          marginBottom={[5, null, null, 0]}
          width={[1, null, null, 2 / 8]}
        >
          <Box className={styles.title} as="span">
            {title}
          </Box>
        </Column>
        <Column width={[1, null, null, 6 / 8]}>
          {posts &&
            posts
              .slice(0, 2)
              .map((post: any) => (
                <PostListItem key={post.id} post={post} linkType={linkType} />
              ))}

          {showMore &&
            showAll &&
            posts &&
            posts
              .slice(2, posts.length)
              .map((post: any) => (
                <PostListItem key={post.id} post={post} linkType={linkType} />
              ))}
          <Flex marginTop={[4]}>
            <Box width={[1 / 4, null, null, 1 / 6]} />
            {showMore && !showAll && (
              <button
                className={styles.showMore}
                onClick={() => {
                  setShowAll(true)
                }}
              >
                <span>{t('showMore')}</span>
                <svg
                  viewBox="0 0 72 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L36 9.13525L71 1" stroke="#D4CC33" />
                </svg>
              </button>
            )}
            {showAll && (
              <button
                className={styles.showLess}
                onClick={() => {
                  setShowAll(false)
                }}
              >
                <svg
                  viewBox="0 0 72 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L36 9.13525L71 1" stroke="#D4CC33" />
                </svg>
                <span>{t('showLess')}</span>
              </button>
            )}
          </Flex>
          {url && (
            <Row
              marginX={['-10px', null, null, '-8px']}
              marginTop={[4]}
              flexDirection="row"
            >
              <Column
                paddingX={['10px', null, null, 2]}
                width={[1 / 4, null, null, 1 / 6]}
              />
              <Column
                paddingX={['10px', null, null, 2]}
                width={[3 / 4, null, null, 5 / 6]}
              >
                <LinkComponent className={styles.showAll} to={url}>
                  <span>{t('showAll')}</span>
                </LinkComponent>
              </Column>
            </Row>
          )}
        </Column>
      </Row>
    </Column>
  )
}

export default PostList
