import React from 'react'
import { Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Image from 'src/components/UI/Image/Image'
import Components from 'src/storyblok/Components'

import * as styles from './LinkGrid.module.scss'
import { Props } from './types'

const LinkGrid = ({ blok }: Props) => {
  const { links, image } = blok

  return (
    <SbEditable content={blok}>
      <Flex className={styles.wrapper} width={1}>
        <Flex
          width={image && image.filename ? [1, null, null, 1 / 2] : 1}
          flexDirection={
            image && image.filename ? ['column'] : ['column', null, null, 'row']
          }
        >
          {links &&
            links.map((blok: any, index: number) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
                color:
                  links.length > 1
                    ? index > 0
                      ? '#213f39'
                      : '#d4cc33'
                    : '#213f39',
                textColor:
                  links.length > 1
                    ? index > 0
                      ? '#ffffff'
                      : '#000000'
                    : '#ffffff',
              })
            )}
        </Flex>
        {image && image.filename && (
          <Flex
            style={{ position: 'relative' }}
            width={1 / 2}
            className="hideMobile"
          >
            <Image src={image.filename} alt={image.title} size="50vw" />
          </Flex>
        )}
      </Flex>
    </SbEditable>
  )
}

export default LinkGrid
