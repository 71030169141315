import * as React from 'react'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './YellowButton.module.scss'
import { Props } from './types'

const YellowButton = ({
  children,
  disabled,
  onClick,
  onSubmit,
  className,
  buttonType,
  to,
}: Props): JSX.Element => {
  return to ? (
    <LinkComponent
      to={to}
      onClick={onClick}
      className={[
        disabled ? styles.disabledButton : styles.button,
        className,
      ].join(' ')}
    >
      {children}
    </LinkComponent>
  ) : (
    <button
      type={buttonType}
      disabled={disabled}
      className={[
        disabled ? styles.disabledButton : styles.button,
        className,
      ].join(' ')}
      onClick={onClick}
      onSubmit={onSubmit}
    >
      {children}
    </button>
  )
}

export default YellowButton
