import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import Components from 'src/storyblok/Components'
import Markdown from 'src/components/Markdown/Markdown'
import SEO from 'src/components/SEO/SEO'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import * as styles from './PageNewsPost.module.scss'
import { Props } from './types'

const PageNewsPost = ({ blok, date, name }: Props) => {
  const { t } = useTranslation()
  const { title, seo, content, file_downloads, component } = blok

  const { seo_title, seo_description, seo_image }: any =
    seo && seo.length > 0 ? seo[0] : {}

  const handleLinkClick = () => {
    const CF = window?.CookieFirst || null

    if (CF) {
      CF.openPanel()
    }
  }

  return (
    <SbEditable content={blok}>
      <Container className={styles.wrapper}>
        <SEO
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
          bodyClass="dark-header"
        />
        <Box
          paddingTop={[15, null, null, 20]}
          paddingBottom={[15, null, null, 15]}
        >
          <Row marginBottom={[2, null, null, 10]}>
            <Column width={1}>
              <Box className={styles.title} as="h1">
                {title ? title : name ? name : ''}
              </Box>
            </Column>
          </Row>
          <Row>
            <Column
              width={[1, null, null, 4 / 12]}
              marginBottom={[10, null, null, 0]}
            >
              {component !== 'page_english' && (
                <Box className={styles.preamble}>
                  {date && format(parseISO(date), 'dd LLLL, y')}
                </Box>
              )}
            </Column>
            <Column width={[1, null, null, 8 / 12]}>
              <Box className={styles.preamble}>
                {content && <Markdown source={content} />}
              </Box>
              {file_downloads?.length > 0 && (
                <Row marginTop={[15, null, null, 18]}>
                  {file_downloads.map((blok: any, index: number) => (
                    <Column
                      key={blok._uid}
                      width={[1, null, null, 1 / 2]}
                      marginTop={index !== 0 ? [5, null, null, 0] : 0}
                    >
                      {React.createElement(Components(blok.component), {
                        key: blok._uid,
                        blok: blok,
                      })}
                    </Column>
                  ))}
                </Row>
              )}
              {name === 'Cookies' && (
                <Flex justifyContent="flex-start">
                  <button
                    className={styles.cookieButton}
                    onClick={() => handleLinkClick()}
                  >
                    {t('cookieSettings')}
                  </button>
                </Flex>
              )}
            </Column>
          </Row>
        </Box>
      </Container>
    </SbEditable>
  )
}

export default PageNewsPost
