import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import YouTube from 'react-youtube'

import Image from 'src/components/UI/Image/Image'
import { PlayIcon } from 'src/components/UI/Elements/Icon'

import * as styles from './Media.module.scss'
import { Props } from './types'

const Media = (props: Props) => {
  const { className, blok } = props
  const { type, youtube_id, poster_image, caption } = blok

  const [videoActive, setMediaActive] = useState(false)
  let videoTarget: any = null

  const opts = {
    height: '100%',
    width: '100%',
  }

  const onMediaReady = (e: any) => {
    videoTarget = e.target
  }

  const onClick = () => {
    setMediaActive(true)
    if (videoTarget) {
      videoTarget.playMedia()
    }
  }

  const wrapperStyles = [styles.wrapper, className]

  return (
    <SbEditable content={blok}>
      <Flex className={wrapperStyles.join(' ')} flexDirection="column">
        {type === 'youtube' && youtube_id && (
          <Box className={styles.videoWrapper} width={1}>
            {!videoActive && (
              <Flex
                alignItems="center"
                justifyContent="center"
                onClick={onClick}
                className={styles.poster}
              >
                <PlayIcon className={styles.playButton} />
                {poster_image && poster_image.filename ? (
                  <Image
                    src={poster_image.filename}
                    size={'100vw'}
                    alt={poster_image.title}
                  />
                ) : (
                  <img
                    src={`https://img.youtube.com/vi/${youtube_id}/0.jpg`}
                    alt={poster_image.title}
                  />
                )}
              </Flex>
            )}
            <YouTube onReady={onMediaReady} opts={opts} videoId={youtube_id} />
          </Box>
        )}
        <Flex
          flexDirection="column"
          paddingTop={caption ? [3, null, null, 4] : 0}
        >
          {caption && (
            <Box as="span" className={styles.caption}>
              {caption}
            </Box>
          )}
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default Media
