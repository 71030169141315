import React from 'react'
import { Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Container from 'src/components/UI/Grid/Container'
import Image from 'src/components/UI/Image/Image'
import Components from 'src/storyblok/Components'

import * as styles from './InfoGrid.module.scss'
import { Props } from './types'

const InfoGrid = ({ blok, imageLeft }: Props) => {
  const { image, tables, google_map } = blok

  const hasMap = google_map && google_map[0]
  return (
    <SbEditable content={blok}>
      <Flex width={1}>
        <Flex
          order={imageLeft ? 1 : 0}
          className={styles.content}
          flexDirection="column"
          paddingTop={[20]}
          paddingBottom={[8]}
          width={[1, null, null, 1 / 2]}
        >
          <Container>
            {tables &&
              tables.map((blok: any) =>
                React.createElement(Components(blok.component), {
                  key: blok._uid,
                  blok: blok,
                })
              )}
          </Container>
        </Flex>
        <Flex
          order={imageLeft ? 0 : 1}
          className="hideMobile"
          style={{ position: 'relative' }}
          width={1 / 2}
        >
          {!hasMap && image && image.filename && (
            <Image
              className={styles.image}
              src={image.filename}
              size={'50vw'}
              alt={image.title}
            />
          )}
          {hasMap &&
            google_map &&
            google_map.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            )}
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default InfoGrid
