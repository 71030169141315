import * as yup from 'yup'

const contactSchema = yup
  .object({
    sureName: yup.string().required(),
    lastName: yup.string().required(),
    company: yup.string().required(),
    phone: yup.string().required(),
    email: yup
      .string()
      .required()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i),
    message: yup.string(),
  })
  .required()

export { contactSchema }
