import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { useObserver } from 'mobx-react'

import { getSeoFromHero } from 'src/helpers/getSeoFromHero'
import RecentPosts from 'src/components/RecentPosts/RecentPosts'
import SEO from 'src/components/SEO/SEO'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import { stringToSlug } from 'src/helpers/stringToSlug'
import Components from 'src/storyblok/Components'
import useStore from 'src/stores/useStore'
import SectionNavigation from 'src/components/SectionNavigtion/SectionNavigation'

import * as styles from './PageAbout.module.scss'
import { Props } from './types'

const PageAbout = ({ blok, full_slug }: Props) => {
  const {
    seo,
    hero,
    preamble,
    description,
    content,
    file_downloads,
    press_releases,
    hide_introduction,
  } = blok

  let { seo_description }: any = seo && seo.length > 0 ? seo[0] : {}
  const { seo_title, seo_image } = getSeoFromHero(seo, hero)
  if (!seo_description && preamble) {
    seo_description = preamble
  }

  const { press } = useStore()

  const navigationLinks =
    content && content.length > 0
      ? content
          .filter(
            (item: any) =>
              item.component === 'text_section' ||
              item.component === 'contact_list'
          )
          .map((item: any) => ({
            title: item.title,
            id: stringToSlug(item.title),
          }))
      : []
  let keyword = ''

  if (press_releases?.length > 0) {
    keyword = press_releases[0].cision_keyword
  }
  if (keyword && press) {
    if (
      press.keywordPosts[keyword] &&
      press.keywordPosts[keyword].fetched === false
    ) {
      press.fetchByKeyword(keyword)
    }
  }

  return useObserver(() => {
    const recentPosts: any[] = []
    const clientPosts = press.entriesByKey(keyword)
    if (clientPosts && clientPosts.length > 0) {
      recentPosts.push({
        posts: clientPosts,
        title: press_releases[0].title,
        url: press_releases[0].link ? press_releases[0].link : null,
        linkType: 'external',
      })
    } else if (press_releases?.length > 0) {
      keyword = press_releases[0].cision_keyword
      recentPosts.push({
        posts: press_releases[0].posts,
        title: press_releases[0].title,
        url: press_releases[0].link ? press_releases[0].link : null,
        linkType: 'external',
      })
    }
    return (
      <SbEditable content={blok}>
        <Flex flexDirection="column" width={1}>
          <SEO
            title={seo_title}
            metaDescription={seo_description}
            image={seo_image}
          />
          {hero &&
            hero.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            )}
          {!hide_introduction && (
            <Container
              paddingY={[16, null, null, 20]}
              className={styles.intro}
              as="section"
            >
              <Row>
                <Column
                  className={styles.nav}
                  marginBottom={[14, null, null, 0]}
                  width={[1, null, null, 4 / 12]}
                >
                  {navigationLinks && navigationLinks.length > 0 && (
                    <SectionNavigation
                      slug={full_slug}
                      links={navigationLinks}
                    />
                  )}
                </Column>
                <Column width={[1, null, null, 6 / 12]}>
                  <Box marginBottom={[8]} as="p" className={styles.preamble}>
                    {preamble}
                  </Box>
                </Column>
                <Column width={[1, null, null, 8 / 12]}>
                  <Box as="p" className={styles.description}>
                    {description}
                  </Box>
                </Column>
              </Row>
            </Container>
          )}
          {content &&
            content.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            )}
          {recentPosts?.length > 0 && <RecentPosts sections={recentPosts} />}
          {file_downloads &&
            file_downloads.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
                noSPacing: true,
              })
            )}
        </Flex>
      </SbEditable>
    )
  })
}

export default PageAbout
