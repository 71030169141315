// extracted by mini-css-extract-plugin
export var doubleLayout = "RealEstates-module--doubleLayout--QCJIY";
export var estate = "RealEstates-module--estate--mz43X";
export var estateLink = "RealEstates-module--estateLink--9ABvG";
export var fourColumnLayout = "RealEstates-module--fourColumnLayout--l7pPT";
export var icon = "RealEstates-module--icon--CZFJS";
export var image = "RealEstates-module--image--udcBx";
export var name = "RealEstates-module--name--oJRiu";
export var singleLayout = "RealEstates-module--singleLayout--zDWrg";
export var text = "RealEstates-module--text--lxWDy";
export var tripleLayout = "RealEstates-module--tripleLayout--YrJ0P";
export var twoColumnLayout = "RealEstates-module--twoColumnLayout--AnLv-";
export var wrapper = "RealEstates-module--wrapper--7FrLq";