import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'

import Container from 'src/components/UI/Grid/Container'
import Markdown from 'src/components/Markdown/Markdown'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { ArrowRightIcon } from 'src/components/UI/Elements/Icon'

import * as styles from './TextAndCtaTwoColumn.module.scss'
import { Props } from './types'

const TextAndCtaTwoColumn = ({ blok }: Props) => {
  const { text, title, cta_text, cta_slug, theme } = blok
  return (
    <SbEditable content={blok}>
      <Flex
        flexDirection={['column', null, null, 'row']}
        className={classnames(
          styles.wrapper,
          theme === 'yellow' ? styles.yellowTheme : ''
        )}
        width={1}
      >
        <Flex
          paddingY={[17, null, null, 22]}
          className={styles.content}
          flexDirection="column"
          width={[1, null, null, 1 / 2]}
        >
          <Container>
            <Box marginBottom={[13, null, null, 17]} as="h3">
              {title}
            </Box>
            <Markdown source={text} />
          </Container>
        </Flex>
        <Box className={styles.cta}>
          <LinkComponent to={cta_slug}>
            <Container height="100%">
              <Flex
                flexDirection="column"
                height="100%"
                justifyContent="space-between"
                width={1}
              >
                <ArrowRightIcon width={50} height={50} />
                {cta_text}
              </Flex>
            </Container>
          </LinkComponent>
        </Box>
      </Flex>
    </SbEditable>
  )
}

export default TextAndCtaTwoColumn
