import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import SEO from 'src/components/SEO/SEO'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import PostItems from 'src/components/PostItems/PostItems'

import * as styles from './PageNewsLanding.module.scss'
import { Props } from './types'

const PageNewsLanding = ({ blok }: Props) => {
  const { title, seo, posts } = blok
  const { seo_title, seo_description, seo_image }: any =
    seo && seo.length > 0 ? seo[0] : {}

  return (
    <SbEditable content={blok}>
      <Container className={styles.wrapper}>
        <SEO
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
          bodyClass="dark-header"
        />
        <Row marginBottom={[3, null, null, 8]} paddingTop={[20]}>
          <Column width={1}>
            <Box className={styles.title} as="h1">
              {title}
            </Box>
          </Column>
        </Row>
        <Row paddingBottom={[15]}>
          <Column width={[1, null, null, 4 / 12]} />
          <Column width={[1, null, null, 8 / 12]}>
            <PostItems posts={posts} />
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default PageNewsLanding
