import React from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Container from 'src/components/UI/Grid/Container'
import {
  ArrowRightUpIcon,
  ArrowRightIcon,
} from 'src/components/UI/Elements/Icon'

import * as styles from './LinkColumn.module.scss'
import { Props } from './types'

const LinkColumn = ({
  blok,
  color = '#d4cc33',
  textColor = '#000000',
}: Props) => {
  const { title, slug } = blok
  const isExternal =
    slug.substr(0, 4) === 'http' || slug.substr(0, 7) === 'mailto:'
  return (
    <SbEditable content={blok}>
      <LinkComponent className={styles.link} to={slug}>
        <Box
          bg={color}
          width={1}
          className={
            isExternal
              ? [styles.wrapper, styles.external].join(' ')
              : styles.wrapper
          }
        >
          <Container
            paddingY={[6, null, null, 12]}
            flexDirection="column"
            justifyContent="space-between"
            className={styles.content}
          >
            {isExternal ? (
              <ArrowRightUpIcon width={44} height={44} color={textColor} />
            ) : (
              <ArrowRightIcon width={55} height={55} color={textColor} />
            )}

            <Box className={styles.title} color={textColor} as="span">
              {title}
            </Box>
          </Container>
        </Box>
      </LinkComponent>
    </SbEditable>
  )
}

export default LinkColumn
