import React from 'react'
import SbEditable from 'storyblok-react'

import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './AddressRow.module.scss'
import { Props } from './types'

const AddressRow = ({ blok }: Props) => {
  const { title, text } = blok
  return (
    <SbEditable content={blok}>
      <Row marginBottom={[6]} className={styles.wrapper} flexDirection="row">
        <Column width={[1 / 4, null, null, 2 / 6]}>
          <p>{title}</p>
        </Column>
        <Column width={[3 / 4, null, null, 4 / 6]}>
          <Markdown source={text} />
        </Column>
      </Row>
    </SbEditable>
  )
}

export default AddressRow
