import * as React from 'react'
import { useState, useEffect } from 'react'
import { Box, Flex } from 'reflexbox'
import isNode from 'is-node'
// import { autorun } from 'mobx'
import { useObserver } from 'mobx-react'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'

import YellowButton from 'src/components/UI/YellowButton/YellowButton'
import Markdown from 'src/components/Markdown/Markdown'
import { storageKeys } from 'src/constants'
import useStore from 'src/stores/useStore'

import { Props } from './types'
import * as styles from './Disclaimer.module.scss'

const DisclaimerContent = ({
  blok,
  cookie,
  onClick,
  className,
}: Props): JSX.Element => {
  const { title, content, button_text }: any = blok || {}
  const wrapperStyles = classnames(
    styles.cookieWrapper,
    cookie.hide ? styles.hidden : '',
    className
  )
  return (
    <Box
      paddingX={[4, null, null, 7]}
      paddingBottom={[24, null, null, 7]}
      width={1}
      className={wrapperStyles}
    >
      <Flex
        flexDirection={['column', null, null, 'column']}
        alignItems="flex-start"
        paddingX={[4, null, null, 9]}
        paddingY={[4, null, null, 9]}
        width={1}
        className={styles.innerWrapper}
      >
        <Box
          as="h3"
          className={styles.title}
          width={1}
          marginBottom={[8, null, null, 12]}
        >
          {title}
        </Box>
        <Box
          className={styles.text}
          marginBottom={[2, null, null, 0]}
          width={[1, null, null, 7 / 12]}
          marginLeft={[0, null, null, (4 / 12) * 100 + '%']}
        >
          <Markdown source={content} />
          <Box width={[1, null, null, 'auto']} marginTop={[6, null, null, 6]}>
            <YellowButton className={styles.button} onClick={onClick}>
              <span>{button_text}</span>
            </YellowButton>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

const Disclaimer = ({ enabled, id, preview, blok }: Props): JSX.Element => {
  const { ui } = useStore()
  const [cookie, setCookie] = useState({ hide: false, show: false })

  const disclaimerAccepted = `${storageKeys.DISCLAIMER_ACCEPTED}-${id}`

  useEffect(() => {
    if (!isNode) {
      if (enabled) {
        const cookieAccepted = localStorage.getItem(disclaimerAccepted)

        if (!cookieAccepted) {
          setCookie({
            ...cookie,
            show: true,
          })
          document.body.classList.add('prevent-overflow')
          ui.setActiveModal('disclaimer')

          return () => {
            document.body.classList.remove('prevent-overflow')
            ui.setActiveModal('')
          }
        }
      }
    }
  }, [])

  const handleClick = () => {
    if (!isNode) {
      setCookie({
        ...cookie,
        hide: true,
      })
      document.body.classList.remove('prevent-overflow')
      ui?.setActiveModal('')

      setTimeout(() => {
        localStorage.setItem(disclaimerAccepted, JSON.stringify(true))
        setCookie({
          ...cookie,
          show: false,
        })
      }, 1000)
    }
  }

  const { title, content, buttonText }: any = ui?.disclaimerContent || {}

  return useObserver(() => {
    return !preview ? (
      <React.Fragment>
        {cookie.show ? (
          <DisclaimerContent
            blok={{ title, content, button_text: buttonText }}
            cookie={cookie}
            onClick={() => handleClick()}
          />
        ) : null}
      </React.Fragment>
    ) : (
      <SbEditable content={blok}>
        <DisclaimerContent
          className={styles.preview}
          blok={blok || {}}
          cookie={cookie}
          onClick={() => handleClick()}
        />
      </SbEditable>
    )
  })
}

export default Disclaimer
