import loadable from '@loadable/component'

// Page types
import PageStart from './Pages/PageStart/PageStart'
import PageAbout from './Pages/PageAbout/PageAbout'
import PageInvestmentLanding from './Pages/PageInvestmentLanding/PageInvestmentLanding'
import PageInvestmentFund from './Pages/PageInvestmentFund/PageInvestmentFund'
import PageInvestmentProperty from './Pages/PageInvestmentProperty/PageInvestmentProperty'
import PageContact from './Pages/PageContact/PageContact'
import PagePress from './Pages/PagePress/PagePress'
import PageNewsLanding from './Pages/PageNewsLanding/PageNewsLanding'
import PageEventLanding from './Pages/PageEventLanding/PageEventLanding'
import PageEventPost from './Pages/PageEventPost/PageEventPost'
import PagePressreleaseLanding from './Pages/PagePressreleaseLanding/PagePressreleaseLanding'
import PageNewsPost from './Pages/PageNewsPost/PageNewsPost'

// Global Modules
import Header from './GlobalModules/Header/Header'
import Footer from './GlobalModules/Footer/Footer'
import CookieConsent from './GlobalModules/CookieConsent/CookieConsent'
import Disclaimer from './GlobalModules/Disclaimer/Disclaimer'

// Components
import ComponentNotFound from './ComponentNotFound'
import LinkColumn from './Components/LinkColumn/LinkColumn'
import ContactCard from './Components/ContactCard/ContactCard'
import FileDownload from './Components/FileDownload/FileDownload'
import DownloadSection from './Components/DownloadSection/DownloadSection'
import Table from './Components/Table/Table'
import FileButton from './Components/FileButton/FileButton'
import MaterialCard from './Components/MaterialCard/MaterialCard'
// import MapComponent from './Components/MapComponent/MapComponent'
import AddressRow from './Components/AddressRow/AddressRow'
import StoryblokSEO from './Components/StoryblokSEO/StoryblokSEO'
import Media from './Components/Media/Media'
import Highlight from './Components/Highlight/Highlight'

// Modules
import Hero from './Modules/Hero/Hero'
import TextSection from './Modules/TextSection/TextSection'
import LinkGrid from './Modules/LinkGrid/LinkGrid'
import ContactGrid from './Modules/ContactGrid/ContactGrid'
import FullWidthImage from './Modules/FullWidthImage/FullWidthImage'
import DownloadList from './Modules/DownloadList/DownloadList'
import InfoGrid from './Modules/InfoGrid/InfoGrid'
import Newsletter from './Modules/Newsletter/Newsletter'
import VideoList from './Modules/VideoList/VideoList'
import TextAndCta from './Modules/TextAndCta/TextAndCta'
import TextAndCtaTwoColumn from './Modules/TextAndCtaTwoColumn/TextAndCtaTwoColumn'
import StartCase from './Modules/StartCase/StartCase'
import TripleLinks from './Modules/TripleLinks/TripleLinks'
import ContactList from './Modules/ContactList/ContactList'
import TripleHighlights from './Modules/TripleHighlights/TripleHighlights'
import TriplePosts from './Modules/TriplePosts/TriplePosts'
import Summary from './Modules/Summary/Summary'
import TableGrid from './Modules/TableGrid/TableGrid'
import ContactForm from './Modules/ContactForm/ContactForm'
import Interview from './Modules/Interview/Interview'

const MapComponent = loadable(
  () => import('./Components/MapComponent/MapComponent')
)

const ComponentList: any = {
  // Page types
  page_start: PageStart,
  page_about: PageAbout,
  page_investment_landing: PageInvestmentLanding,
  page_investment_fund: PageInvestmentFund,
  page_investment_property: PageInvestmentProperty,
  page_contact: PageContact,
  page_press: PagePress,
  page_news_landing: PageNewsLanding,
  page_event_landing: PageEventLanding,
  page_event_post: PageEventPost,
  page_pressrelease_landing: PagePressreleaseLanding,
  page_news_post: PageNewsPost,
  page_english: PageNewsPost,

  // Global Modules
  header: Header,
  footer: Footer,
  cookie_consent: CookieConsent,
  disclaimer: Disclaimer,

  // Components
  link_column: LinkColumn,
  contact_card: ContactCard,
  file_download: FileDownload,
  file_button: FileButton,
  download_section: DownloadSection,
  table: Table,
  material_card: MaterialCard,
  google_maps: MapComponent,
  address_row: AddressRow,
  seo: StoryblokSEO,
  media: Media,
  highlight: Highlight,

  // Modules
  hero: Hero,
  text_section: TextSection,
  link_grid: LinkGrid,
  contact_grid: ContactGrid,
  full_width_image: FullWidthImage,
  download_list: DownloadList,
  info_grid: InfoGrid,
  newsletter: Newsletter,
  video_list: VideoList,
  text_and_cta: TextAndCta,
  text_and_cta_two_column: TextAndCtaTwoColumn,
  start_case: StartCase,
  triple_links: TripleLinks,
  contact_list: ContactList,
  triple_highlights: TripleHighlights,
  triple_posts: TriplePosts,
  summary: Summary,
  table_grid: TableGrid,
  contact_form: ContactForm,
  interview: Interview,
}

const Components = (type: string) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
