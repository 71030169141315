import * as React from 'react'
import { Flex, Box } from 'reflexbox'

import PostItem from 'src/components/PostItems/PostItem/PostItem'

// import * as styles from './PostItems.module.scss'
import { Props } from './types'

const PostItems = ({ posts }: Props) => {
  return (
    <Flex flexDirection="column" width={1}>
      {posts?.length > 0 &&
        posts.map((post: any) => {
          return <PostItem key={post.id} post={post} />
        })}
    </Flex>
  )
}

export default PostItems
