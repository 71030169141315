import * as React from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { stringToSlug } from 'src/helpers/stringToSlug'
import Components from 'src/storyblok/Components'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import * as styles from './ContactList.module.scss'
import { Props } from './types'

const RecentPosts = ({ blok }: Props) => {
  const { title, contacts } = blok
  return (
    <SbEditable content={blok}>
      <Container
        id={title ? stringToSlug(title) : ''}
        className={styles.wrapper}
        paddingY={[17, null, null, 20]}
      >
        <Box
          className={styles.title}
          as="h2"
          marginBottom={[9, null, null, 12]}
        >
          {title}
        </Box>
        <Row className={styles.workerWrapper}>
          {contacts.map((blok: any) => (
            <Column
              key={blok._uid}
              marginBottom={[17, null, null, 20]}
              width={[1, null, null, 1 / 4]}
            >
              {React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })}
            </Column>
          ))}
        </Row>
      </Container>
    </SbEditable>
  )
}

export default RecentPosts
