import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { useTranslation } from 'react-i18next'

import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Components from 'src/storyblok/Components'

import * as styles from './DownloadSection.module.scss'
import { Props } from './types'

const DownloadSection = ({ blok }: Props) => {
  const { t } = useTranslation()
  const [showAll, setShowAll] = useState(false)
  const { title, files } = blok
  const showMore = files.length > 2
  return (
    <SbEditable content={blok}>
      <Column marginBottom={[14]} width={[1, null, null, 8 / 12]}>
        <Row>
          <Column
            marginBottom={[4, null, null, 0]}
            width={[1, null, null, 2 / 8]}
          >
            <Box className={styles.title} as="span">
              {title}
            </Box>
          </Column>
          <Column flexDirection={'column'} width={[1, null, null, 6 / 8]}>
            {files &&
              files.slice(0, 2).map((blok: any) =>
                React.createElement(Components(blok.component), {
                  key: blok._uid,
                  blok: blok,
                })
              )}
            {showMore &&
              showAll &&
              files &&
              files.slice(2, files.length).map((blok: any) =>
                React.createElement(Components(blok.component), {
                  key: blok._uid,
                  blok: blok,
                })
              )}
            <Row marginTop={[4]} marginX={['-10px', null, null, '-8px']}>
              <Column
                paddingX={['10px', null, null, 2]}
                width={[1 / 4, null, null, 1 / 6]}
              />
              <Column
                paddingX={['10px', null, null, 2]}
                width={[3 / 4, null, null, 5 / 6]}
              >
                {showMore && !showAll && (
                  <Box
                    onClick={() => {
                      setShowAll(true)
                    }}
                    as="button"
                    className={styles.moreButton}
                  >
                    <span>{t('showMore')}</span>
                    <svg
                      viewBox="0 0 72 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1 1L36 9.13525L71 1" stroke="#D4CC33" />
                    </svg>
                  </Box>
                )}
                {showMore && showAll && (
                  <Box
                    onClick={() => {
                      setShowAll(false)
                    }}
                    as="button"
                    className={styles.lessButton}
                  >
                    <svg
                      viewBox="0 0 72 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1 1L36 9.13525L71 1" stroke="#D4CC33" />
                    </svg>
                    <span>{t('showLess')}</span>
                  </Box>
                )}
              </Column>
            </Row>
          </Column>
        </Row>
      </Column>
    </SbEditable>
  )
}

export default DownloadSection
