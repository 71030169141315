import React from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Markdown from 'src/components/Markdown/Markdown'
import { stringToSlug } from 'src/helpers/stringToSlug'
import Components from 'src/storyblok/Components'

import * as styles from './TextSection.module.scss'
import { Props } from './types'

const TextSection = ({ blok }: Props) => {
  const { title, preamble, text, use_read_more, file_downloads, theme } = blok

  const wrapperClassName = [
    styles.wrapper,
    theme === 'brown' ? styles.brownTheme : '',
  ].join(' ')
  return (
    <SbEditable content={blok}>
      <Container
        className={wrapperClassName}
        id={title ? stringToSlug(title) : ''}
        paddingTop={[14, null, null, 19]}
        paddingBottom={[17, null, null, 26]}
      >
        <Row
          marginBottom={preamble ? [7, null, null, 13] : [3, null, null, 13]}
          flexDirection="column"
        >
          <Column width={[1, null, null, 6 / 12]}>
            <Box
              marginBottom={[7, null, null, 9]}
              className={styles.title}
              as="h2"
            >
              {title}
            </Box>
          </Column>
          {preamble && (
            <Column width={[1, null, null, 9 / 12]}>
              <Box as="p" className={styles.preamble}>
                {preamble}
              </Box>
            </Column>
          )}
        </Row>
        <Row>
          <Column
            marginRight={[0, null, null, `${(1 / 12) * 100}%`]}
            marginLeft={[0, null, null, `${(4 / 12) * 100}%`]}
            width={1}
            className={styles.text}
          >
            <Markdown readMore={use_read_more} source={text} />
            {file_downloads?.length > 0 && (
              <Row marginTop={[10]}>
                {file_downloads.map((blok: any, index: number) => (
                  <Column
                    key={blok._uid}
                    width={[1, null, null, 1 / 2]}
                    marginTop={index === 1 ? [5, null, null, 0] : 0}
                  >
                    {React.createElement(Components(blok.component), {
                      key: blok._uid,
                      blok: blok,
                    })}
                  </Column>
                ))}
              </Row>
            )}
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default TextSection
