import React, { useEffect } from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'

import Label from '../Label/Label'
import * as styles from './InputText.module.scss'
import { Props } from './types'

const InputText = ({
  name,
  label,
  error,
  warning,
  register,
  type,
  forceFocus,
  onChange,
  required,
  placeholder,
}: Props) => {
  useEffect(() => {
    if (forceFocus) {
      const el = document.getElementById(name)
      if (el) {
        el.focus()
      }
    }
  }, [])

  return (
    <Flex
      className={classnames(styles.wrapper, error ? styles.error : '')}
      flexDirection="column"
      width={1}
      style={{ position: 'relative' }}
    >
      {label ? (
        <Flex justifyContent="space-between" width={1}>
          <Label htmlFor={name} id={`label_${name}`}>
            {label}
            {required ? '*' : ''}
          </Label>
          {error?.message && (
            <Box className={styles.error} as="span">
              Please fill out this field
            </Box>
          )}
        </Flex>
      ) : null}
      <Box width={1}>
        <input
          className={classnames(styles.input, warning ? styles.warning : '')}
          id={name}
          aria-labelledby={`label_${name}`}
          name={name}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value)
            }
          }}
          type={type}
          required={required}
          placeholder={
            placeholder ? `${placeholder}${required ? '*' : ''}` : ''
          }
          {...register(name)}
        />
      </Box>
    </Flex>
  )
}

export default InputText
