import React from 'react'
import { parseISO, format } from 'date-fns'
import classnames from 'classnames'

import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import {
  ArrowRightIcon,
  ArrowRightUpIcon,
  DownloadIcon,
} from 'src/components/UI/Elements/Icon'

import * as styles from './PostListItem.module.scss'
import { Props } from './types'

const PostListItem = ({ post, linkType }: Props) => {
  const { url, id, date, title } = post

  const newDate = date ? parseISO(date) : null

  return url ? (
    <LinkComponent key={id} to={url} className={styles.link}>
      <Row
        flexDirection="row"
        flexWrap="no-wrap"
        alignItems="flex-start"
        flex="1"
        marginX={['-10px', null, null, '-8px']}
      >
        {newDate && (
          <Column
            paddingX={['10px', null, null, 2]}
            textAlign={['center', null, null, 'left']}
            alignItems={['center', null, null, 'flex-start']}
            justifyContent="flex-start"
            width={[1 / 4, null, null, 1 / 6]}
            height="100%"
          >
            <span className={styles.date}>
              {format(newDate, 'd/M')}
              <span className={classnames('hideMobile')}>
                {`, ${format(newDate, 'Y')}`}
              </span>
            </span>
            <span className={classnames(styles.date, 'hideDesktop')}>
              {format(newDate, 'Y')}
            </span>
          </Column>
        )}
        {title && (
          <Column
            paddingX={['10px', null, null, 2]}
            width={[3 / 4, null, null, 5 / 6]}
            as="span"
            className={styles.title}
          >
            <span>{title}</span>
          </Column>
        )}
        {linkType && (
          <Column
            paddingX={['10px', null, null, 2]}
            className={[styles.actionIcon, 'hideMobile'].join(' ')}
            textAlign="right"
            justifyContent="flex-end"
            alignItems="flex-start"
            width="auto"
            as="span"
          >
            {linkType === 'download' && (
              <DownloadIcon color="black" width={20} height={23} />
            )}
            {linkType === 'external' && (
              <ArrowRightUpIcon color="black" width={20} height={23} />
            )}
            {linkType === 'internal' && (
              <ArrowRightIcon color="black" width={23} height={23} />
            )}
          </Column>
        )}
      </Row>
    </LinkComponent>
  ) : null
}

export default PostListItem
