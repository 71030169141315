import React from 'react'
import { Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Container from 'src/components/UI/Grid/Container'
import Components from 'src/storyblok/Components'

import * as styles from './TableGrid.module.scss'
import { Props } from './types'

const TableGrid = ({ blok }: Props) => {
  const { column_1, column_2 } = blok

  return (
    <SbEditable content={blok}>
      <Flex
        width={1}
        className={styles.wrapper}
        flexDirection={['column', null, null, 'row']}
      >
        {column_1?.length > 0 && (
          <Flex
            flexDirection="column"
            paddingTop={[8, null, null, 20]}
            paddingBottom={[0, null, null, 8]}
            width={[1, null, null, 1 / 2]}
          >
            <Container>
              {column_1.map((table: any) =>
                React.createElement(Components(table.component), {
                  key: table._uid,
                  blok: table,
                })
              )}
            </Container>
          </Flex>
        )}
        {column_2?.length > 0 && (
          <Flex
            flexDirection="column"
            paddingTop={[0, null, null, 20]}
            paddingBottom={[4, null, null, 8]}
            width={[1, null, null, 1 / 2]}
          >
            <Container>
              {column_2.map((table: any) =>
                React.createElement(Components(table.component), {
                  key: table._uid,
                  blok: table,
                })
              )}
            </Container>
          </Flex>
        )}
      </Flex>
    </SbEditable>
  )
}

export default TableGrid
