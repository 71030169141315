import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { getSeoFromHero } from 'src/helpers/getSeoFromHero'
import Markdown from 'src/components/Markdown/Markdown'
import SEO from 'src/components/SEO/SEO'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Components from 'src/storyblok/Components'

import * as styles from './PageInvestmentProperty.module.scss'
import { Props } from './types'
import { useTranslation } from 'react-i18next'

const PageInvestmentProperty = ({ blok }: Props) => {
  const {
    hero,
    preamble,
    description,
    link_section,
    info_section,
    text_section,
    newsletter_section,
    materials,
    seo,
  } = blok
  const { t } = useTranslation()
  let { seo_description }: any = seo && seo.length > 0 ? seo[0] : {}
  const { seo_title, seo_image } = getSeoFromHero(seo, hero)
  if (!seo_description && preamble) {
    seo_description = preamble
  }

  const totalLinks =
    link_section &&
    link_section[0] &&
    link_section[0].links &&
    link_section[0].links.length

  return (
    <SbEditable content={blok}>
      <Flex width={1} flexDirection="column">
        <SEO
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
        />
        {hero &&
          hero.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
        <Container paddingY={[20]}>
          <Row>
            <Column width={[1, null, null, 9 / 12]}>
              {preamble && (
                <Box className={styles.preamble} as="p">
                  {preamble}
                </Box>
              )}
            </Column>
            {description && (
              <Column
                width={[1, null, null, 7 / 12]}
                marginTop={[8, null, null, 12]}
                marginLeft={[0, null, null, `${(4 / 12) * 100}%`]}
                justifyContent="flex-end"
              >
                <Markdown source={description} />
              </Column>
            )}
          </Row>
        </Container>
        {link_section &&
          link_section.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
        {info_section &&
          info_section.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
              imageLeft: totalLinks > 1,
            })
          )}
        {text_section &&
          text_section.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
              imageLeft: totalLinks > 1,
            })
          )}
        {newsletter_section &&
          newsletter_section.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
        {materials && materials.length > 0 && (
          <Container>
            <Flex paddingTop={[16]} flexDirection="column" width={1}>
              <Box
                className={styles.materialTitle}
                as="h3"
                marginBottom={[13, null, null, 18]}
              >{`${t('imagesOf')} ${hero[0].title}`}</Box>
              <Row>
                {materials &&
                  materials.map((blok: any, index: number) => (
                    <Column
                      key={blok._uid}
                      marginBottom={[12, null, null, 18]}
                      width={[1, null, null, 1 / 3]}
                    >
                      {React.createElement(Components(blok.component), {
                        key: blok._uid,
                        blok: blok,
                        index: index + 1,
                      })}
                    </Column>
                  ))}
              </Row>
            </Flex>
          </Container>
        )}
      </Flex>
    </SbEditable>
  )
}

export default PageInvestmentProperty
