import * as React from 'react'
import { Flex } from 'reflexbox'

import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'

import PostList from './PostList/PostList'
import * as styles from './RecentPosts.module.scss'
import { Props } from './types'

const RecentPosts = ({ sections, noSpacing, startGrid }: Props) => {
  return (
    <Container
      className={styles.wrapper}
      paddingTop={noSpacing ? 0 : [11, null, null, startGrid ? 23 : 16]}
      paddingBottom={[2, null, null, 2]}
    >
      <Row
        className={startGrid ? styles.startGrid : ''}
        alignItems="center"
        flexDirection={startGrid ? 'row' : 'column'}
      >
        {sections &&
          sections.map((section: any, index: number) => {
            return section?.posts?.length > 0 ? (
              <PostList
                key={`recent-${index}`}
                title={section.title}
                posts={section.posts}
                url={section.url}
                linkType={section.linkType}
              />
            ) : null
          })}
      </Row>
    </Container>
  )
}

export default RecentPosts
