import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { Pie } from 'react-chartjs-2'

import Markdown from 'src/components/Markdown/Markdown'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import * as styles from './Summary.module.scss'
import { Props } from './types'

const Summary = ({ blok }: Props) => {
  const { title, text, chart } = blok

  const chartComponent = chart?.length ? chart[0] : {}
  const chartTotal = chartComponent?.total_amount
    ? chartComponent.total_amount
    : 0

  const chartData =
    chartComponent?.data?.length > 0
      ? chartComponent.data.map((data: any) => parseFloat(data.amount))
      : null
  const chartLabels =
    chartComponent?.data?.length > 0
      ? chartComponent.data.map((data: any) => `${data.label}`)
      : null

  const colors = [
    '#213F39',
    '#76B8AC',
    '#D4CC33',
    '#8F4A39',
    '#C4D1A3',
    '#A6998C',
  ]
  const data = {
    labels: chartLabels,

    datasets: [
      {
        data: chartData,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        borderWidth: 5,
        borderColor: '#e8e4dc',
      },
    ],
  }
  const options = {
    layout: {},
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  }
  return (
    <SbEditable content={blok}>
      <Box data-title={title} className={styles.wrapper} width={1}>
        <Container width={1} height="100%" paddingY={[15, null, null, 20]}>
          {title && (
            <Box className={styles.title} as="h1">
              {title}
            </Box>
          )}
          <Row>
            <Column width={[1, null, null, 8 / 12]}>
              <Box>
                {text && (
                  <Box className={styles.text} marginTop={[10, null, null, 20]}>
                    <Markdown source={text} />
                  </Box>
                )}
              </Box>
            </Column>
            <Column width={[1, null, null, 4 / 12]} padding="0">
              {chartData && (
                <Box
                  // paddingX={['15%']}
                  marginBottom={[10]}
                  marginTop={[4, null, null, 0]}
                >
                  <Pie data={data} options={options} width={58} height={58} />
                </Box>
              )}
              {chartLabels?.length > 0 && (
                <Flex flexWrap="wrap" marginBottom={[4]}>
                  {chartLabels.map((label: string, index: number) => (
                    <Flex
                      width={1 / 2}
                      key={label}
                      className={styles.label}
                      alignItems="center"
                      marginBottom={4}
                    >
                      <Box
                        bg={colors[index]}
                        height="24px"
                        width="24px"
                        marginRight={4}
                      />
                      <Box as="span" marginBottom="2px">
                        {`${label}, ${parseFloat(
                          (chartData[index] / chartTotal) * 100
                        )}%`}
                      </Box>
                    </Flex>
                  ))}
                </Flex>
              )}
              <Box marginLeft={[10]}>
                <Box as="span" className={styles.total}>
                  Totalt: {chartTotal}{' '}
                  {chartComponent?.type && chartComponent.type}
                </Box>
              </Box>
            </Column>
          </Row>
        </Container>
      </Box>
    </SbEditable>
  )
}

export default Summary
