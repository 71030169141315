import React from 'react'
import { Box, Flex } from 'reflexbox'

import Image from 'src/components/UI/Image/Image'

import * as styles from './FullWidthImage.module.scss'
import { Props } from './types'

const FullWidthImage = ({ blok }: Props) => {
  const { image, image_description } = blok
  return (
    <Flex as="section" style={{ position: 'relative' }} width={1}>
      {image && image.filename && (
        <Image
          className={styles.image}
          src={image.filename}
          alt={image.title}
          size="100vw"
        />
      )}
      {image_description && (
        <div>
          <Box
            paddingY={[8]}
            paddingX={[16]}
            className={styles.description}
            width={[1 / 2]}
          >
            {image_description}
          </Box>
        </div>
      )}
    </Flex>
  )
}

export default FullWidthImage
