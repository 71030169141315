import React from 'react'
import { Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Components from 'src/storyblok/Components'

import { Props } from './types'
import * as styles from './TripleHighlights.module.scss'

const TripleHighlights = ({ blok }: Props) => {
  const { highlights } = blok
  return (
    <SbEditable content={blok}>
      <Flex
        flexDirection={['column', null, null, 'row']}
        className={styles.wrapper}
        width={1}
      >
        {highlights &&
          highlights.map((blok: any, index: number) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
              color:
                index === 0 ? '#EDEBE8' : index === 1 ? '#D4CC33' : '#213F39',
              textColor: index > 1 ? '#FFFFFF' : '#000000',
            })
          )}
      </Flex>
    </SbEditable>
  )
}

export default TripleHighlights
