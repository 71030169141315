import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { getSeoFromHero } from 'src/helpers/getSeoFromHero'
import SEO from 'src/components/SEO/SEO'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Components from 'src/storyblok/Components'
import ContactList from 'src/storyblok/Modules/ContactList/ContactList'

import * as styles from './PageContact.module.scss'
import { Props } from './types'
import { useTranslation } from 'react-i18next'

const PageContact = ({ blok }: Props) => {
  const {
    seo,
    hero,
    preamble,
    // address_fields,
    // google_map,
    coworkers,
    // board_members,
  } = blok
  const { t } = useTranslation()

  const { seo_description }: any = seo && seo.length > 0 ? seo[0] : {}
  const { seo_title, seo_image } = getSeoFromHero(seo, hero)

  return (
    <SbEditable content={blok}>
      <Flex flexDirection="column" width={1}>
        <SEO
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
        />
        {hero &&
          hero.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
        <Container
          paddingTop={[17, null, null, 20]}
          paddingBottom={[0, null, null, 20]}
        >
          {preamble && (
            <Row>
              <Column width={[1, null, null, 9 / 12]}>
                <Box
                  marginBottom={[20, null, null, '130px']}
                  className={styles.preamble}
                  as="p"
                >
                  {preamble}
                </Box>
              </Column>
            </Row>
          )}
          {/* <Row>
            <Column
              marginBottom={[20, null, null, 0]}
              width={[1, null, null, 6 / 12]}
            >
              {address_fields &&
                address_fields.map((blok: any) =>
                  React.createElement(Components(blok.component), {
                    key: blok._uid,
                    blok: blok,
                  })
                )}
            </Column>
            <Column
              width={['auto', null, null, 6 / 12]}
              marginBottom={[17, null, null, 20]}
            >
              <Box
                className={styles.mapContainer}
                height={['340px', null, null, 'auto']}
              >
                <Box marginX={['-28px', null, null, '0']}>
                  {google_map &&
                    google_map.map((blok: any) =>
                      React.createElement(Components(blok.component), {
                        key: blok._uid,
                        blok: blok,
                      })
                    )}
                </Box>
              </Box>
            </Column>
          </Row> */}
          {coworkers?.length > 0 && (
            <ContactList blok={{ title: t('team'), contacts: coworkers }} />
          )}
          {/* {board_members?.length > 0 && (
            <React.Fragment>
              <Box
                marginBottom={[9, null, null, 12]}
                className={styles.workerTitle}
                as="h2"
              >
                Styrelsen
              </Box>
              <Row className={styles.workerWrapper}>
                {board_members.map((blok: any) => (
                  <Column
                    key={blok._uid}
                    marginBottom={[17, null, null, 20]}
                    width={[1, null, null, 1 / 4]}
                  >
                    {React.createElement(Components(blok.component), {
                      key: blok._uid,
                      blok: blok,
                    })}
                  </Column>
                ))}
              </Row>
            </React.Fragment>
          )} */}
        </Container>
      </Flex>
    </SbEditable>
  )
}

export default PageContact
