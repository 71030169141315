import * as React from 'react'
import { useState, useEffect } from 'react'
import { Box, Flex } from 'reflexbox'
import isNode from 'is-node'
import SbEditable from 'storyblok-react'

import YellowButton from 'src/components/UI/YellowButton/YellowButton'
import Markdown from 'src/components/Markdown/Markdown'
import { storageKeys } from 'src/constants'

import { Props } from './types'
import * as styles from './CookieConsent.module.scss'

const Content = ({ blok, cookie, onClick }: Props): JSX.Element => {
  const { content, button_text }: any =
    blok?.content?.content || blok?.content?.button_text
      ? blok.content
      : blok
      ? blok
      : {}

  return (
    <Box
      paddingX={[4, null, null, 7]}
      paddingBottom={[4, null, null, 7]}
      width={1}
      className={
        cookie.hide ? [styles.cookieWrapper].join(' ') : styles.cookieWrapper
      }
    >
      <Flex
        flexDirection={['column', null, null, 'row']}
        justifyContent={['flex-start', null, null, 'space-between']}
        alignItems="flex-start"
        paddingX={[4, null, null, 9]}
        paddingY={[4, null, null, 9]}
        width={1}
        className={styles.innerWrapper}
      >
        <Box
          className={styles.text}
          marginBottom={[2, null, null, 0]}
          width={[1, null, null, 7 / 12]}
        >
          {content && <Markdown source={content} />}
        </Box>
        <Box width={[1, null, null, 3 / 12]} marginTop={[6, null, null, 0]}>
          <YellowButton onClick={onClick}>
            {button_text && <span>{button_text}</span>}
          </YellowButton>
        </Box>
      </Flex>
    </Box>
  )
}

const CookieConsent = ({ blok, preview }: Props): JSX.Element => {
  const [cookie, setCookie] = useState({ hide: false, show: false })

  useEffect(() => {
    if (!isNode) {
      const cookieAccepted = localStorage.getItem(storageKeys.COOKIE_ACCEPTED)
      if (!cookieAccepted) {
        setCookie({
          ...cookie,
          show: true,
        })
      }
    }
  }, [])

  const handleClick = () => {
    setCookie({
      ...cookie,
      hide: true,
    })
    setTimeout(() => {
      localStorage.setItem(storageKeys.COOKIE_ACCEPTED, JSON.stringify(true))
      setCookie({
        ...cookie,
        show: false,
      })
    }, 1000)
  }

  return <Content blok={blok} cookie={cookie} />
}

export default CookieConsent
