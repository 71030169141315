import isNode from 'is-node'

const format = (image: string, resize: string, filter: string) => {
  var imageService = 'https://img2.storyblok.com/'
  var path = image.replace('https://a.storyblok.com', '')
  return imageService + resize + filter + path
}

// Check if webp is accepted
export const formatImage = (
  src: string,
  size: number,
  ratio: number,
  strictWidth?: string
) => {
  let filters = 'filters:quality(85)'
  let webpAcceppted = false
  if (!isNode && document) {
    webpAcceppted = document.body.classList.contains('support-webp')
  }

  if (webpAcceppted) {
    filters = filters + ':format(webp)'
  }
  if (src && src !== '') {
    if (size) {
      const width = size

      const height = ratio ? Math.round(width / ratio) : 0

      return format(src, `${width}x${height}/`, filters)
    } else if (strictWidth) {
      return format(src, `${strictWidth}x/`, filters)
    } else {
      return format(src, '', filters)
    }
  }
  return ''
}
