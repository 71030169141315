import React from 'react'
import { Box, Flex } from 'reflexbox'

import * as styles from './FileButton.module.scss'
import { Props } from './types'

const FileButton = ({ blok }: Props) => {
  const { file, title } = blok

  return (
    <a
      title={title}
      href={file.filename}
      target="_blank"
      download
      className={styles.button}
    >
      <Flex alignItems="center" marginRight={[4, null, null]}>
        <svg viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.03971 0L0 7.65269V23H17V0H7.03971ZM6.57131 3.47441V7.04766H3.28428L6.57131 3.47441ZM15.0713 20.9034H1.92869V9.14429H8.5V2.09663H15.0713V20.9034Z"
            fill="black"
          />
        </svg>
      </Flex>
      <Box as="span">{title}</Box>
    </a>
  )
}

export default FileButton
