import { Link } from 'gatsby'
import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import scrollIntoView from 'scroll-into-view'

import { stringToSlug } from 'src/helpers/stringToSlug'
import { getSeoFromHero } from 'src/helpers/getSeoFromHero'
import Components from 'src/storyblok/Components'
import SEO from 'src/components/SEO/SEO'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { ArrowRightIcon } from 'src/components/UI/Elements/Icon'
import SectionNavigation from 'src/components/SectionNavigtion/SectionNavigation'

import * as styles from './PageInvestmentLanding.module.scss'
import { Props } from './types'
import { useTranslation } from 'react-i18next'

const PageInvestmentLanding = ({ blok, full_slug }: Props) => {
  const { hero, title, preamble, list_items, closed_funds, seo } = blok
  const { t } = useTranslation()

  const { seo_description }: any = seo && seo.length > 0 ? seo[0] : {}
  const { seo_title, seo_image } = getSeoFromHero(seo, hero)

  const closedFundsTitle = t('finishedFunds')

  // Setup section navigation links
  const listItemLinks =
    list_items && list_items?.length > 0
      ? list_items.map((item: any) => {
          const { title }: any =
            item?.content?.hero && item?.content?.hero.length > 0
              ? item.content.hero[0]
              : {}

          return {
            title: title,
            id: title ? stringToSlug(title) : '',
          }
        })
      : []
  const navigationLinks = [...listItemLinks]

  if (title) {
    navigationLinks.unshift({
      title: title,
      id: title ? stringToSlug(title) : '',
    })
  }
  if (closed_funds && closed_funds.length > 0 && closedFundsTitle) {
    navigationLinks.push({
      title: closedFundsTitle,
      id: closedFundsTitle ? stringToSlug(closedFundsTitle) : '',
    })
  }
  return (
    <SbEditable content={blok}>
      <SEO
        title={seo_title}
        metaDescription={seo_description}
        image={seo_image}
      />
      {hero?.length > 0 &&
        hero.map((blok: any) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
          })
        )}
      {navigationLinks && navigationLinks.length > 0 && (
        <Container
          paddingY={[16, null, null, 20]}
          className={styles.sectionNavigation}
        >
          <SectionNavigation slug={full_slug} links={navigationLinks} />
        </Container>
      )}
      <Container id={title ? stringToSlug(title) : ''}>
        <Row>
          <Column
            paddingTop={[19]}
            paddingBottom={[24]}
            width={[1, null, null, 10 / 12]}
          >
            {title && (
              <Box
                marginBottom={[3, null, null, 4]}
                className={styles.title}
                as="h2"
                width={[3 / 4, null, null, 1]}
              >
                {title}
              </Box>
            )}
            {preamble && (
              <Box className={styles.preamble} as="p">
                {preamble}
              </Box>
            )}
          </Column>
        </Row>
      </Container>
      <Box width={1}>
        {list_items &&
          list_items.length > 0 &&
          list_items.map((item: any) => {
            if (typeof item === 'string') {
              return null
            }
            const { content, full_slug } = item || {}
            const { hero, list_description, list_details }: any = content
              ? content
              : {}
            const { image, title }: any = hero ? hero[0] : {}

            return full_slug ? (
              <Flex
                id={title ? stringToSlug(title) : ''}
                flexDirection={['column', null, null, 'row']}
                className={styles.listItem}
                key={`open-${full_slug}`}
              >
                <Link className={styles.imageWrapper} to={`/${full_slug}`}>
                  {image?.filename && (
                    <Image src={image.filename} alt={title} size="50vw" />
                  )}
                </Link>
                <Flex
                  paddingTop={[9, null, null, 9, null, '4%']}
                  flexDirection="column"
                  width={[1, null, null, 1 / 2]}
                >
                  <Flex
                    flexDirection="column"
                    paddingBottom={[16]}
                    paddingX={[7, null, null, 17]}
                  >
                    {title && (
                      <Box
                        marginBottom={[6, null, null, 21]}
                        as="span"
                        className={styles.listTitle}
                      >
                        {title}
                      </Box>
                    )}
                    {list_description && (
                      <Box
                        marginBottom={8}
                        className={styles.listDescription}
                        as="p"
                      >
                        {list_description}
                      </Box>
                    )}
                    {list_details &&
                      (list_details?.thead[0]?.value ||
                        list_details?.tbody[0]?.value) && (
                        <table className={styles.table}>
                          <tbody>
                            {list_details.thead && (
                              <tr>
                                {list_details.thead.map((head: any) => (
                                  <th key={head._uid}>{head.value}</th>
                                ))}
                              </tr>
                            )}
                            {list_details.tbody &&
                              list_details.tbody.map((tb: any) => (
                                <tr key={tb._uid}>
                                  {tb.body &&
                                    tb.body.map((inner: any) => (
                                      <td key={inner._uid}>{inner.value}</td>
                                    ))}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                  </Flex>
                  <LinkComponent className={styles.button} to={`/${full_slug}`}>
                    <Flex
                      paddingX={[7, null, null, 17]}
                      alignItems="center"
                      justifyContent="space-between"
                      width={1}
                    >
                      {t('readMoreFund')}
                      <ArrowRightIcon width={30} height={30} />
                    </Flex>
                  </LinkComponent>
                </Flex>
              </Flex>
            ) : null
          })}
        {closed_funds && closed_funds.length > 0 && (
          <React.Fragment>
            <Container
              className={styles.closedItems}
              id={stringToSlug(closedFundsTitle)}
            >
              <Box
                paddingTop={[9, null, null, 16]}
                paddingBottom={[5, null, null, 5]}
                className={styles.title}
                as="h2"
              >
                {closedFundsTitle}
              </Box>
            </Container>
            <Flex
              flexDirection={['column', null, null, 'row']}
              flexWrap="wrap"
              className={styles.closedItems}
            >
              {closed_funds.map((item: any) => {
                if (typeof item === 'string') {
                  return null
                }
                const { content, full_slug } = item || {}
                const { hero, list_description, list_year }: any = content
                  ? content
                  : {}
                const { title }: any = hero ? hero[0] : {}

                return full_slug ? (
                  <Box
                    width={[1, null, null, 1 / 2]}
                    className={styles.closedItem}
                    key={`closed-${full_slug}`}
                  >
                    <Box
                      paddingY={[9, null, null, 14]}
                      paddingX={[7, null, null, 17]}
                    >
                      {list_year && (
                        <Box
                          marginBottom={[6, null, null, 8]}
                          className={styles.listYear}
                        >
                          {list_year}
                        </Box>
                      )}
                      {title && (
                        <Box
                          marginBottom={[6, null, null, 8]}
                          className={styles.listTitle}
                        >
                          {title}
                        </Box>
                      )}
                      {list_description && (
                        <Box
                          marginBottom={[6, null, null, 8]}
                          className={styles.listDescription}
                          as="p"
                        >
                          {list_description}
                        </Box>
                      )}
                      <LinkComponent
                        className={styles.readMore}
                        to={`/${full_slug}`}
                      >
                        <Box as="span">{t('readMore')}</Box>
                      </LinkComponent>
                    </Box>
                  </Box>
                ) : null
              })}
            </Flex>
          </React.Fragment>
        )}
      </Box>
    </SbEditable>
  )
}

export default PageInvestmentLanding
