import React, { useEffect, useState } from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { parseISO } from 'date-fns'

import SEO from 'src/components/SEO/SEO'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import PostItems from 'src/components/PostItems/PostItems'

import * as styles from './PageEventLanding.module.scss'
import { Props } from './types'

const PageEventLanding = ({ blok }: Props) => {
  const { title, seo, posts } = blok
  const { seo_title, seo_description, seo_image }: any =
    seo && seo.length > 0 ? seo[0] : {}

  const upcomingEvents = posts || []
  const [events, setEvents] = useState(upcomingEvents)

  useEffect(() => {
    // Update events if dates have passed from todays date
    const today = new Date()
    const eventsFromToday = upcomingEvents.filter((event: any) => {
      return parseISO(event.date) >= today
    })

    if (eventsFromToday?.length < upcomingEvents?.length) {
      setEvents(eventsFromToday)
    }
  }, [setEvents])

  return (
    <SbEditable content={blok}>
      <Container className={styles.wrapper}>
        <SEO
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
          bodyClass="dark-header"
        />
        <Row marginBottom={[3, null, null, 8]} paddingTop={[20]}>
          <Column width={1}>
            <Box className={styles.title} as="h1">
              {title}
            </Box>
          </Column>
        </Row>
        <Row paddingBottom={[15]}>
          <Column width={[1, null, null, 4 / 12]} />
          <Column width={[1, null, null, 8 / 12]}>
            <PostItems posts={events} />
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default PageEventLanding
