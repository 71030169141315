import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import InputText from 'src/components/UI/InputText/InputText'
import TextArea from 'src/components/UI/TextArea/TextArea'
import Image from 'src/components/UI/Image/Image'
import Container from 'src/components/UI/Grid/Container'
import Markdown from 'src/components/Markdown/Markdown'
import { ArrowRightIcon } from 'src/components/UI/Elements/Icon'

import * as styles from './ContactForm.module.scss'
import { Props } from './types'
import { contactSchema } from './schemas'

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactForm = ({ blok }: Props) => {
  const {
    title,
    contact_photo,
    contact_info,
    background_image,
    id,
    first_name,
    last_name,
    company,
    phone,
    email,
    message,
    send,
  } = blok

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const resolver = yupResolver(contactSchema)
  const {
    getValues,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: resolver,
  })
  const onSubmit = (data: any) => {
    if (data) {
      const body = encode({ 'form-name': id, ...data })

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: body,
      })
        .then(() => {
          setSuccess(true)
          console.log('Success!')
          setTimeout(() => {
            setSuccess(false)
            reset()
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <SbEditable content={blok}>
      <Flex
        flexDirection={['column', null, null, 'row']}
        className={styles.wrapper}
        width={1}
      >
        <Flex
          paddingTop={[10, null, null, 22]}
          className={styles.form}
          flexDirection="column"
          width={[1, null, null, 1 / 2]}
        >
          <Container>
            <Box marginBottom={[10, null, null, 22]} as="h3">
              {title}
            </Box>
          </Container>
          <Flex
            as="form"
            name={id}
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            flexDirection="column"
            onSubmit={handleSubmit(onSubmit)}
            className={styles.form}
            height="100%"
            novalidate
          >
            <input type="hidden" name="form-name" value={id} />
            <Container height="100%">
              <Flex width={1} marginBottom={8}>
                <Box width={1} marginRight={8}>
                  <InputText
                    name="sureName"
                    placeholder={first_name ? first_name : 'Förnamn'}
                    type="text"
                    register={register}
                    error={errors.sureName}
                    required
                  />
                </Box>
                <Box width={1}>
                  <InputText
                    name="lastName"
                    placeholder={last_name ? last_name : 'Efternamn'}
                    type="text"
                    register={register}
                    error={errors.sureName}
                    required
                  />
                </Box>
              </Flex>
              <Box marginBottom={8}>
                <InputText
                  name="company"
                  placeholder={company ? company : 'Företag eller organisation'}
                  type="text"
                  register={register}
                  error={errors.company}
                  required
                />
              </Box>
              <Box marginBottom={8}>
                <InputText
                  name="phone"
                  placeholder={phone ? phone : 'Telefonnummer'}
                  type="tel"
                  register={register}
                  error={errors.phone}
                  required
                />
              </Box>
              <Box marginBottom={5}>
                <InputText
                  name="email"
                  placeholder={email ? email : 'E-post'}
                  type="email"
                  register={register}
                  error={errors.email}
                  required
                />
              </Box>
              <Box marginBottom={8} flex="2" height="100%">
                <TextArea
                  name="message"
                  placeholder={message ? message : 'Meddelande'}
                  error={errors.message}
                  register={register}
                />
              </Box>
            </Container>
            <Box as="button" className={styles.submit} type="submit">
              <Container height="100%">
                <Flex
                  height="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  width={1}
                >
                  <Box as="span">
                    {error
                      ? 'Try again'
                      : success
                      ? 'Sent'
                      : loading
                      ? 'Sending'
                      : `${send ? send : 'Skicka'}`}
                  </Box>
                  <ArrowRightIcon width={32} height={32} />
                </Flex>
              </Container>
            </Box>
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          className={styles.contact}
          width={[1, null, null, 1 / 2]}
        >
          <Flex>
            {contact_photo?.filename && (
              <Box width={[1, null, null, 3 / 5]}>
                <Box className={styles.contactPhoto}>
                  <Image
                    src={contact_photo.filename}
                    size={'33vw'}
                    alt={contact_photo.title}
                  />
                </Box>
              </Box>
            )}
            <Flex justifyContent="center" alignItems="flex-end" width={1}>
              {contact_info && (
                <Box
                  width={[1, null, null, 3 / 5]}
                  paddingTop={[7, null, null, 10]}
                  paddingBottom={[7, null, null, 20]}
                  paddingX={[7, null, null, 0]}
                >
                  <Markdown source={contact_info} />
                </Box>
              )}
            </Flex>
          </Flex>
          {background_image?.filename && (
            <Box width={1} className={styles.contactImage}>
              <Box height="100%" className={styles.backgroundImage}>
                <Image
                  src={background_image.filename}
                  size={'50vw'}
                  alt={background_image.title}
                />
              </Box>
            </Box>
          )}
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default ContactForm
