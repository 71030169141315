import React, { useState } from 'react'
import { Flex, Box } from 'reflexbox'

import { ArrowRightIcon } from 'src/components/UI/Elements/Icon'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import EstatesMap from './EstatesMap/EstatesMap'
import * as styles from './RealEstatesMap.module.scss'
import { useTranslation } from 'react-i18next'
// import { Props } from './types'

const RealEstatesMap = ({ blok }: Props): JSX.Element => {
  const { real_estates_included } = blok
  const { t } = useTranslation()

  const realEstatesWithGps =
    real_estates_included?.length > 0
      ? real_estates_included.filter((property: any) => {
          return (
            property?.content?.info_section?.length > 0 &&
            property?.content?.info_section[0]?.google_map?.length > 0 &&
            property?.content?.info_section[0]?.google_map[0]?.latitude &&
            property?.content?.info_section[0]?.google_map[0]?.longitude
          )
        })
      : []

  const [selectedProperty, setSelectedProperty] = useState(
    realEstatesWithGps?.length > 0 ? realEstatesWithGps[0].uuid : null
  )

  const mapMarkers = realEstatesWithGps.map((property) => {
    return {
      lat: parseFloat(
        property?.content?.info_section[0].google_map[0].latitude
      ),
      lng: parseFloat(property.content.info_section[0].google_map[0].longitude),
      id: property?.uuid,
      active: property.uuid === selectedProperty,
    }
  })

  const property =
    realEstatesWithGps?.length > 0
      ? realEstatesWithGps.filter(
          (property) => property.uuid === selectedProperty
        )[0]
      : null

  const updateProperty = (id) => {
    setSelectedProperty(id)
  }

  return mapMarkers?.length > 0 ? (
    <Flex
      className={styles.wrapper}
      flexDirection={['column', null, null, 'row']}
    >
      <Box className={styles.selected} width={[1, null, null, 1 / 2]}>
        <Box className={styles.contentWrapper}>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            className={styles.content}
            paddingRight={[7, null, null, 16]}
            paddingLeft={[7, null, null, 16]}
            paddingY={[16, null, null, 18]}
          >
            <Box as="h3">{t('theGeographicLocations')}</Box>
            {property && (
              <LinkComponent
                className={styles.item}
                to={`/${property.full_slug}`}
                title=""
              >
                {`${property?.name ? property.name : ''}${
                  property?.address ? `, ${property.address}` : ''
                }`}
                <ArrowRightIcon className={styles.icon} />
              </LinkComponent>
            )}
          </Flex>
        </Box>
      </Box>
      <Box className={styles.map} width={[1, null, null, 1 / 2]}>
        <EstatesMap markers={mapMarkers} onClick={updateProperty} />
      </Box>
    </Flex>
  ) : (
    <div />
  )
}

export default RealEstatesMap
