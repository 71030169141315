import React from 'react'
import { Box, Flex } from 'reflexbox'

import { FileIcon, DownloadIcon } from 'src/components/UI/Elements/Icon'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import * as styles from './FileDownload.module.scss'
import { Props } from './types'

const getFilename = (url: any) => {
  if (url) {
    const m = url.toString().match(/.*\/(.+?)\./)
    if (m && m.length > 1) {
      return m[1]
    }
  }
  return ''
}

const FileButton = ({ blok }: Props) => {
  const { file } = blok
  let { title } = blok
  const { filename } = file

  if (!title && filename) {
    title = getFilename(filename)
  }

  return (
    <a
      href={file.filename}
      target="_blank"
      rel="noopener noreferrer"
      download
      className={styles.link}
    >
      <Row
        flexDirection="row"
        flexWrap="no-wrap"
        alignItems="flex-start"
        flex="1"
        marginX={['-10px', null, null, '-8px']}
      >
        <Column
          paddingX={['10px', null, null, 2]}
          textAlign="center"
          alignItems="center"
          justifyContent="flex-start"
          width={[1 / 4, null, null, 1 / 6]}
        >
          <FileIcon color="black" width={17} height={23} />
        </Column>
        <Column
          paddingX={['10px', null, null, 2]}
          width={[3 / 4, null, null, 5 / 6]}
          as="span"
          className={styles.title}
        >
          {title}
        </Column>
        <Column
          paddingX={['10px', null, null, 2]}
          textAlign="right"
          justifyContent="flex-end"
          alignItems="flex-start"
          width="auto"
          className={[styles.actionIcon, 'hideMobile'].join(' ')}
        >
          <DownloadIcon color="black" width={20} height={23} />
        </Column>
      </Row>
    </a>
  )
}

export default FileButton
