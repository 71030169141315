import React from 'react'
import { format, parseISO } from 'date-fns'
import { Flex } from 'reflexbox'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import { Props } from './types'
import * as styles from './PostItem.module.scss'

const PostItem = ({ post }: Props) => {
  const { date, title, url, summary, hide_time } = post

  return (
    <LinkComponent to={url} className={styles.item}>
      <Flex className={styles.content} flexDirection="column">
        {date && (
          <span>
            {format(parseISO(date), `d/M, y${!hide_time ? `, H:mm` : ``}`)}
          </span>
        )}
        {title && <h2 className={styles.title}>{title}</h2>}
        {summary && <p>{summary}...</p>}
      </Flex>
    </LinkComponent>
  )
}

export default PostItem
