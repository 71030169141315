import React, { useEffect, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { useObserver } from 'mobx-react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import SEO from 'src/components/SEO/SEO'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import PostItems from 'src/components/PostItems/PostItems'
import useStore from 'src/stores/useStore'

import * as styles from './PagePressreleaseLanding.module.scss'
import { Props } from './types'

const PagePressreleaseLanding = ({ blok }: Props) => {
  const { t } = useTranslation()

  const [filter, setFilter] = useState('all')
  const { title, seo, posts } = blok
  const { seo_title, seo_description, seo_image }: any =
    seo && seo.length > 0 ? seo[0] : {}
  const { press } = useStore()

  useEffect(() => {
    if (press.posts.length < 1) {
      press.fetchNextPosts()
    }
  }, [])
  return useObserver(() => {
    return (
      <SbEditable content={blok}>
        <Container className={styles.wrapper}>
          <SEO
            title={seo_title}
            metaDescription={seo_description}
            image={seo_image}
            bodyClass="dark-header"
          />
          <Row marginBottom={[3, null, null, 8]} paddingTop={[20]}>
            <Column width={1}>
              <Box className={styles.title} as="h1">
                {title}
              </Box>
            </Column>
          </Row>
          <Row paddingBottom={[15]}>
            <Column width={[1, null, null, 4 / 12]} />
            <Column width={[1, null, null, 8 / 12]}>
              <Row>
                <Column width={[1, null, null, 8 / 8]}>
                  <Flex alignItems="flex-start" width={1}>
                    <Box
                      // marginTop={[3, null, null, 0]}
                      marginBottom={[0, null, null, 3]}
                      marginRight={[0, null, null, 15]}
                      as="button"
                      className={classnames(
                        styles.filter,
                        filter === 'all' ? styles.active : ''
                      )}
                      onClick={() => setFilter('all')}
                    >
                      <Box as="span">{t('showAll')}</Box>
                    </Box>
                    <Box
                      // marginTop={[3, null, null, 0]}
                      marginBottom={[0, null, null, 3]}
                      marginRight={[0, null, null, 15]}
                      as="button"
                      className={classnames(
                        styles.filter,
                        filter === true ? styles.active : ''
                      )}
                      onClick={() => setFilter(true)}
                    >
                      <Box as="span">{t('regulatoryPressRelease')}</Box>
                    </Box>
                    <Box
                      marginBottom={[3, null, null, 3]}
                      as="button"
                      className={classnames(
                        styles.filter,
                        filter === false ? styles.active : ''
                      )}
                      onClick={() => setFilter(false)}
                    >
                      <Box as="span">{t('noneRegulatoryPressRelease')}</Box>
                    </Box>
                  </Flex>
                </Column>
              </Row>
              {press?.posts?.length > 0 && (
                <React.Fragment>
                  <PostItems
                    posts={
                      press?.entries && press?.entries.length > 0
                        ? press?.entries.filter(
                            (post) =>
                              filter === 'all' || post.isRegulatory === filter
                          )
                        : posts.slice(0, 20)
                    }
                  />
                  {press.allFetched === false && (
                    <Flex
                      marginTop={[10]}
                      flexDirection="column"
                      as="button"
                      onClick={() => {
                        press.fetchNextPosts()
                      }}
                      className={styles.readMoreButton}
                    >
                      <span>{t('readMore')}</span>
                      <svg
                        viewBox="0 0 72 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1 1L36 9.13525L71 1" stroke="#D4CC33" />
                      </svg>
                    </Flex>
                  )}
                </React.Fragment>
              )}
            </Column>
          </Row>
        </Container>
      </SbEditable>
    )
  })
}

export default PagePressreleaseLanding
