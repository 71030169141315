import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { useTranslation } from 'react-i18next'

import { getSeoFromHero } from 'src/helpers/getSeoFromHero'
import PostItems from 'src/components/PostItems/PostItems'
import Markdown from 'src/components/Markdown/Markdown'
import SEO from 'src/components/SEO/SEO'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Image from 'src/components/UI/Image/Image'
import Components from 'src/storyblok/Components'

import * as styles from './PagePress.module.scss'
import { Props } from './types'

const PagePress = ({ blok }: Props) => {
  const {
    seo,
    hero,
    title,
    preamble,
    content,
    newsletter,
    images,
    logotypes,
    news_title,
    posts,
  } = blok
  const { t } = useTranslation()
  const { seo_description }: any = seo && seo.length > 0 ? seo[0] : {}
  const { seo_title, seo_image } = getSeoFromHero(seo, hero)
  return (
    <SbEditable content={blok}>
      <Flex flexDirection="column" width={1}>
        <SEO
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
        />
        {hero &&
          hero.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
        {content &&
          content.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
        <Container paddingY={[17, null, null, 20]}>
          <Row>
            <Column
              marginBottom={[6, null, null, 2]}
              width={[1, null, null, 7 / 12]}
            >
              <Box className={styles.title} as="h2">
                {title}
              </Box>
            </Column>
            <Column
              marginLeft={[0, null, null, `${(4 / 12) * 100}%`]}
              width={[1, null, null, 7 / 12]}
            >
              <Box className={styles.preamble} as="p">
                <Markdown source={preamble} />
              </Box>
            </Column>
          </Row>
        </Container>
        {(images || logotypes) && (
          <Container
            paddingY={[16, null, null, 20]}
            className={styles.materialWrapper}
          >
            <Row justifyContent="center">
              <Column width={[1, null, null, 8 / 12]}>
                {images && images.length > 0 && (
                  <Row marginBottom={[15]}>
                    <Column
                      width={[1, null, null, 2 / 8]}
                      marginBottom={[10, null, null, 0]}
                    >
                      <Box as="p" className={styles.listTitle}>
                        {t('pressImages')}
                      </Box>
                    </Column>
                    <Column width={[1, null, null, 6 / 8]}>
                      {images.map((image: any, index: number) => {
                        const fileArray = image.filename.split('/')
                        const name = fileArray[fileArray.length - 1]
                        const title = image.title
                          ? image.title
                          : image.alt
                          ? image.alt
                          : ''
                        return (
                          <Flex
                            className={styles.listItem}
                            key={`image_${index}`}
                            paddingY={[4]}
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              className={styles.fileImage}
                              width={[1 / 4, null, null, 2 / 6]}
                            >
                              <Image
                                src={image.filename}
                                size="25vw"
                                alt={title}
                              />
                            </Flex>
                            <Flex
                              paddingLeft={[5, null, null, 10]}
                              width={[3 / 4, null, null, 4 / 6]}
                              flexDirection="column"
                              alignContent="flex-start"
                              justifyContent="flex-start"
                            >
                              {title && (
                                <Box as="span" marginBottom={8}>
                                  {title}
                                </Box>
                              )}
                              {name && <Box as="span">{name}</Box>}
                              <Box marginTop={[8]}>
                                <a
                                  href={image.filename}
                                  download
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {t('showDocument')}
                                </a>
                              </Box>
                            </Flex>
                          </Flex>
                        )
                      })}
                    </Column>
                  </Row>
                )}
                {logotypes && logotypes.length > 0 && (
                  <Row>
                    <Column
                      width={[1, null, null, 2 / 8]}
                      marginBottom={[10, null, null, 0]}
                    >
                      <Box className={styles.listTitle} as="p">
                        {t('logotypes')}
                      </Box>
                    </Column>
                    <Column width={[1, null, null, 6 / 8]}>
                      {logotypes.map((image: any, index: number) => {
                        const fileArray = image.filename.split('/')
                        const name = fileArray[fileArray.length - 1]
                        return (
                          <Flex
                            className={[styles.listItem, styles.logoItem].join(
                              ' '
                            )}
                            key={`image_${index}`}
                            paddingY={[4]}
                            width={1}
                          >
                            <Flex
                              alignContent="center"
                              justifyContent="center"
                              width={[1 / 4, null, null, 2 / 6]}
                            >
                              <Image
                                src={image.filename}
                                size="25vw"
                                alt={image.title}
                              />
                            </Flex>
                            <Flex
                              paddingLeft={[5, null, null, 10]}
                              width={[3 / 4, null, null, 4 / 6]}
                              flexDirection="column"
                              alignContent="flex-start"
                              justifyContent="flex-start"
                            >
                              <Box as="span">{name}</Box>
                              <Box marginTop={[4]}>
                                <a
                                  href={image.filename}
                                  download
                                  target="_blank"
                                >
                                  {t('showDocument')}
                                </a>
                              </Box>
                            </Flex>
                          </Flex>
                        )
                      })}
                    </Column>
                  </Row>
                )}
              </Column>
            </Row>
          </Container>
        )}
        {newsletter &&
          newsletter.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
        <Container paddingY={[16, null, null, 20]}>
          <Row marginBottom={[3, null, null, 8]}>
            <Column width={1}>
              <Box className={styles.title} as="h1">
                {news_title && news_title}
              </Box>
            </Column>
          </Row>
          <Row>
            <Column width={[1, null, null, 4 / 12]} />
            <Column width={[1, null, null, 8 / 12]}>
              {posts?.length > 0 && <PostItems posts={posts} />}
            </Column>
          </Row>
        </Container>
      </Flex>
    </SbEditable>
  )
}
export default PagePress
