import React from 'react'
import { Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Image from 'src/components/UI/Image/Image'
import Components from 'src/storyblok/Components'

import * as styles from './ContactGrid.module.scss'
import { Props } from './types'

const ContactGrid = ({ blok }: Props) => {
  const { image, contact_person } = blok

  return (
    <SbEditable content={blok}>
      <Flex width={1}>
        <Flex
          className="hideMobile"
          style={{ position: 'relative' }}
          width={1 / 2}
        >
          {image && image.filename && (
            <Image
              className={styles.image}
              src={image.filename}
              size={'50vw'}
              alt={image.title}
            />
          )}
        </Flex>
        <Flex width={[1, null, null, 1 / 2]}>
          {contact_person &&
            contact_person.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
                isModule: true,
              })
            )}
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default ContactGrid
