import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Container from 'src/components/UI/Grid/Container'
import Markdown from 'src/components/Markdown/Markdown'
import * as styles from './StartCase.module.scss'
import { Props } from './types'
import Components from 'src/storyblok/Components'

const StartCase = ({ blok }: Props) => {
  const { label, title, description, info } = blok
  return (
    <SbEditable content={blok}>
      <Flex width={1} flexDirection="column" as="section">
        <Container width={1} paddingY={[16, null, null, 27]}>
          <Flex
            className={styles.content}
            flexDirection="column"
            width={[1, null, null, 10 / 12]}
          >
            <Box className={styles.label} marginBottom={[2]} as="span">
              {label}
            </Box>
            <Box
              className={styles.title}
              marginBottom={[7, null, null, 18]}
              as="h3"
            >
              {title}
            </Box>
            <Markdown source={description} />
          </Flex>
        </Container>
        <Box className={styles.info}>
          {info &&
            info.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
                imageLeft: true,
              })
            )}
        </Box>
      </Flex>
    </SbEditable>
  )
}

export default StartCase
