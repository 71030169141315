import React from 'react'

import Media from 'src/storyblok/Components/Media/Media'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import * as styles from './VideoList.module.scss'
import { Props } from './types'

const VideoList = ({ blok }: Props) => {
  const { videos } = blok
  return (
    <Container>
      {videos?.length > 0 && (
        <Row>
          {videos.map((blok: any) => {
            return (
              <Column
                key={blok.id}
                width={[1, null, null, 6 / 12]}
                marginBottom={[10, null, null, 15]}
              >
                <Media className={styles.video} blok={blok} />
              </Column>
            )
          })}
        </Row>
      )}
    </Container>
  )
}

export default VideoList
