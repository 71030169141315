import React from 'react'
import { Flex, Box } from 'reflexbox'

import * as styles from './SectionNavigation.module.scss'
import { Props } from './types'
import { Link } from 'gatsby'

const SectionNavigation = ({ links, slug }: Props): JSX.Element => {
  return (
    <Box className={styles.wrapper}>
      {links &&
        links.length > 0 &&
        links.map((item: any, index: number) => (
          <Link
            onClick={(e: any) => {
              document.documentElement.classList.add('smooth-anchor')
              setTimeout(() => {
                document.documentElement.classList.remove('smooth-anchor')
              }, 250)
            }}
            key={item.id}
            to={`/${slug}#${item.id}`}
          >
            <Flex
              marginBottom={2}
              alignItems={['flex-start', null, null, 'center']}
            >
              <Box as="span" marginRight={2}>
                {`0${index + 1}`}
              </Box>
              <Box className={styles.navItem} as="button">
                {item.title}
              </Box>
            </Flex>
          </Link>
        ))}
    </Box>
  )
}

export default SectionNavigation
