import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Container from 'src/components/UI/Grid/Container'
import Image from 'src/components/UI/Image/Image'
import VideoComponent from 'src/components/UI/VideoComponent/VideoComponent'
import { ArrowRightIcon } from 'src/components/UI/Elements/Icon'

import * as styles from './Hero.module.scss'
import { Props } from './types'

const Hero = ({ blok }: Props) => {
  const { title, image, image_mobile, video, button, size } = blok
  const isFull = size === 'full'
  const height = isFull ? '100vh' : '80vh'
  const { title: linkTitle = '', slug = '' } =
    button && button?.length > 0 ? button[0] : {}

  const hasLink = linkTitle && slug
  const hasVideo = video?.filename

  const scrollDown = () => {
    if (window) {
      window.scrollTo({
        top: window.innerHeight,
        behavior: 'smooth',
      })
    }
  }

  return (
    <SbEditable content={blok}>
      <Box
        id="hero"
        height={height}
        data-title={title}
        className={styles.wrapper}
        width={1}
      >
        {isFull && (
          <Flex
            as="button"
            className={styles.arrowButton}
            onClick={() => scrollDown()}
          >
            <ArrowRightIcon width={32} height={32} />
          </Flex>
        )}

        <Container
          paddingTop={[28, null, null, 36]}
          paddingBottom={
            !hasLink && isFull ? [36, null, null, 34] : [12, null, null, 12]
          }
          className={styles.content}
          width={1}
          height="100%"
          justifyContent={hasLink ? 'center' : 'flex-end'}
        >
          <Flex
            width={[1, null, null, 10 / 12]}
            justifyContent={['flex-end', null, null, 'space-between']}
            alignItems="flex-start"
            flexDirection="column"
          >
            {title && (
              <Box
                className={styles.title}
                as="h1"
                marginBottom={hasLink ? [isFull ? 12 : 20, null, null, 12] : 0}
              >
                {title}
              </Box>
            )}
            {hasLink && (
              <LinkComponent to={slug} className={styles.button}>
                {linkTitle}
                <ArrowRightIcon width={32} height={32} />
              </LinkComponent>
            )}
          </Flex>
        </Container>
        {hasVideo && <VideoComponent src={video.filename} />}
        {!hasVideo && image?.filename && (
          <Image
            src={image.filename}
            className={image_mobile?.filename ? 'hideMobile' : ''}
            size="100vw"
            alt="title"
          />
        )}
        {!hasVideo && image_mobile?.filename && (
          <Image
            src={image_mobile.filename}
            className="hideDesktop"
            size="100vw"
            alt="title"
          />
        )}
      </Box>
    </SbEditable>
  )
}

export default Hero
