export const getSeoFromHero = (seo: any, hero: any) => {
  if (!seo) {
    return {}
  }
  let { seo_title, seo_image }: any = seo && seo.length > 0 ? seo[0] : {}

  if (hero?.length > 0) {
    if (!seo_title && hero[0]?.title) {
      seo_title = hero[0].title
    }
    if (!seo_image && hero[0]?.image?.filename) {
      seo_image = hero[0].image.filename
    }
  }

  return { seo_title, seo_image }
}
