import React from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { format, parseISO } from 'date-fns'

import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import * as styles from './PageEventPost.module.scss'

const PageEventPost = ({ blok, name }: any) => {
  const { date, endDate, description, location, hide_time } = blok

  return (
    <SbEditable content={blok}>
      <Container className={styles.wrapper}>
        <Row paddingTop={[20]} paddingBottom={[15]}>
          <Column width={[1, null, null, 8 / 12]}>
            <span className={styles.label}>Title</span>
            <Box marginBottom={[3]} className={styles.title} as="h1">
              {name && name}
            </Box>
          </Column>
          <Column width={[1, null, null, 4 / 12]}>
            <Box className={styles.preamble} as="p" marginBottom={7}>
              <Box marginBottom={2} className={styles.label}>
                Start Date
              </Box>
              {date &&
                format(parseISO(date), `d/M, y${!hide_time ? `, H:mm` : ``}`)}
            </Box>

            {endDate ? (
              <Box className={styles.preamble} as="p" marginBottom={7}>
                <Box marginBottom={2} className={styles.label}>
                  End Date
                </Box>
                {format(parseISO(endDate), 'd/M, y, H:mm')}
              </Box>
            ) : (
              <Box className={styles.preamble} as="p" marginBottom={7}>
                <Box marginBottom={2} className={styles.label}>
                  Duration
                </Box>
                1 Hour
              </Box>
            )}

            {description && (
              <Box className={styles.preamble} as="p" marginBottom={7}>
                <Box marginBottom={2} className={styles.label}>
                  Description
                </Box>
                {description}
              </Box>
            )}

            {location && (
              <Box className={styles.preamble} as="p" marginBottom={7}>
                <Box marginBottom={2} className={styles.label}>
                  Location
                </Box>
                {location}
              </Box>
            )}
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default PageEventPost
