import React, { useEffect } from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'
import TextareaAutosize from 'react-textarea-autosize'

import Label from '../Label/Label'
import * as styles from './TextArea.module.scss'
import { Props } from './types'

const TextArea = ({
  name,
  label,
  error,
  register,
  forceFocus,
  onChange,
  required,
  placeholder,
  autoResize,
}: Props) => {
  useEffect(() => {
    if (forceFocus) {
      const el = document.getElementById(name)
      if (el) {
        el.focus()
      }
    }
  }, [])

  return (
    <Flex
      className={classnames(styles.wrapper, error ? styles.error : '')}
      flexDirection="column"
      width={1}
      style={{ position: 'relative' }}
      height="100%"
    >
      {label && (
        <Flex justifyContent="space-between" width={1}>
          <Label htmlFor={name} id={`label_${name}`}>
            {label}
            {required ? ' *' : ''}
          </Label>
        </Flex>
      )}
      <Box width={1} height="100%">
        {autoResize ? (
          <TextareaAutosize
            className={styles.textArea}
            id={name}
            name={name}
            aria-labelledby={`label_${name}`}
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.value)
              }
            }}
            required={required}
            placeholder={
              placeholder ? `${placeholder}${required ? '*' : ''}` : ''
            }
            {...register(name)}
          />
        ) : (
          <Box
            as="textarea"
            className={styles.textArea}
            id={name}
            name={name}
            aria-labelledby={`label_${name}`}
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.value)
              }
            }}
            required={required}
            placeholder={
              placeholder ? `${placeholder}${required ? '*' : ''}` : ''
            }
            {...register(name)}
          />
        )}
      </Box>
    </Flex>
  )
}

export default TextArea
