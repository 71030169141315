import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Markdown from 'src/components/Markdown/Markdown'
import Container from 'src/components/UI/Grid/Container'
import Image from 'src/components/UI/Image/Image'

import * as styles from './ContactCard.module.scss'
import { Props } from './types'

const ContactCard = ({ blok, isModule }: Props) => {
  const { image, quote, name, title, contact_info, extra_title } = blok

  return isModule ? (
    <SbEditable content={blok}>
      <Container
        paddingTop={[8, null, null, 13]}
        paddingBottom={[11]}
        className={
          isModule ? [styles.wrapper, styles.dark].join(' ') : styles.wrapper
        }
        width={1}
        alignItems="flex-end"
      >
        <Flex
          flexDirection="column"
          alignItems="flex-end"
          width={[1, null, null, 1]}
        >
          {image && image.filename && (
            <Box width={[1, null, null, 4 / 6]}>
              <Image
                ratio={3 / 4}
                src={image.filename}
                size={'50vw'}
                alt={image.title}
              />
            </Box>
          )}
          {quote && (
            <Box
              className={styles.quote}
              marginTop={image?.filename ? [6, null, null, 8] : 0}
              width={[1, null, null, 1]}
            >
              <Markdown source={quote} />
            </Box>
          )}
          <Flex flexDirection="column" width={[1, null, null, 4 / 6]}>
            {extra_title && (
              <Box
                marginTop={[4]}
                className={[styles.extraTitle, 'hideMobile'].join(' ')}
                as="p"
              >
                {extra_title}
              </Box>
            )}
            {name && (
              <Box marginTop={[extra_title ? 0 : 4, null, null, 8]} as="p">
                {name}
              </Box>
            )}
            {title && <Box as="p">{title}</Box>}
            {contact_info && (
              <Box marginTop={[6, null, null, 8]}>
                <Markdown source={contact_info} />
              </Box>
            )}
          </Flex>
        </Flex>
      </Container>
    </SbEditable>
  ) : (
    <SbEditable content={blok}>
      <Flex className={styles.wrapper} flexDirection="column" width={1}>
        {image && image.filename && (
          <Box className="hideMobile" width={1}>
            <Image
              ratio={3 / 4}
              src={image.filename}
              size={'50vw'}
              alt={image.title}
            />
          </Box>
        )}
        <Flex flexDirection="column">
          {extra_title && (
            <Box
              marginTop={[4]}
              className={[styles.extraTitle, 'hideMobile'].join(' ')}
              as="p"
            >
              {extra_title}
            </Box>
          )}
          <Box marginTop={[0, null, null, 8]} as="p">
            {name}
          </Box>
          <Box as="p">{title}</Box>
          <Box marginTop={[6, null, null, 8]}>
            <Markdown source={contact_info} />
          </Box>
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default ContactCard
