import * as React from 'react'
import { Flex, Box } from 'reflexbox'
import { Link } from 'gatsby'
import SbEditable from 'storyblok-react'

import Image from 'src/components/UI/Image/Image'
import { ArrowRightIcon } from 'src/components/UI/Elements/Icon'
import Table from 'src/storyblok/Components/Table/Table'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './RealEstates.module.scss'
import { Props } from './types'

const RealEstates = ({ blok }: Props) => {
  const {
    real_estates_included: realEstates,
    real_estates_title: title,
    real_estates_text: text,
  } = blok

  const totalItems =
    realEstates && realEstates.length > 0 ? realEstates.length : -1
  const singleLayout = totalItems === 1
  const doubleLayout = totalItems === 2
  const tripleLayout = totalItems === 3
  // const oneColumnLayout = totalItems > 3 && totalItems < 10
  const twoColumnLayout = totalItems > 9 && totalItems < 19
  const fourColumnLayout = totalItems > 18
  const image =
    singleLayout &&
    realEstates[0]?.content?.hero?.length > 0 &&
    realEstates[0]?.content?.hero[0]?.image?.filename
      ? realEstates[0].content.hero[0].image.filename
      : null

  return realEstates && realEstates.length > 0 ? (
    <SbEditable content={blok}>
      <Container paddingX={0} className={styles.wrapper}>
        <Flex flexWrap="wrap">
          <Box
            paddingRight={[7, null, null, '20px']}
            paddingLeft={[7, null, null, 16]}
            paddingTop={
              !fourColumnLayout ? [20, null, null, 25] : [16, null, null, 18]
            }
            paddingBottom={
              !fourColumnLayout ? [20, null, null, 25] : [8, null, null, 10]
            }
            width={[1, null, null, !fourColumnLayout ? 1 / 2 : 1]}
          >
            <Row>
              <Column width={[1, null, null, 5 / 6]}>
                {title && (
                  <Box as="h3" width={[1, null, null, 8 / 12]}>
                    {title}
                  </Box>
                )}
                {text && (
                  <Box marginTop={[10, null, null, 10]}>
                    <Markdown source={text} />
                  </Box>
                )}
              </Column>
            </Row>
          </Box>
          <Box
            className={styles.estates}
            width={[1, null, null, !fourColumnLayout ? 1 / 2 : 1]}
            paddingLeft={
              singleLayout || doubleLayout
                ? [0, null, null, '20px']
                : [7, null, null, '20px']
            }
            paddingRight={
              singleLayout || doubleLayout
                ? [0, null, null, 16]
                : [7, null, null, 16]
            }
            paddingY={
              singleLayout || doubleLayout ? 0 : ['20px', null, null, '20px']
            }
          >
            <Row
              flexDirection={[
                'column',
                null,
                null,

                doubleLayout || twoColumnLayout || fourColumnLayout
                  ? 'row'
                  : 'column',
              ]}
              height={twoColumnLayout || fourColumnLayout ? 'auto' : '100%'}
            >
              {realEstates.map((estate: any) => {
                const tables =
                  (singleLayout || doubleLayout) &&
                  estate?.content?.info_section.length > 0 &&
                  estate.content.info_section[0]?.tables
                    ? estate.content.info_section[0].tables
                    : null

                return (
                  <Column
                    className={[
                      styles.estate,
                      singleLayout ? styles.singleLayout : '',
                      doubleLayout ? styles.doubleLayout : '',
                      tripleLayout ? styles.tripleLayout : '',
                      twoColumnLayout ? styles.twoColumnLayout : '',
                      fourColumnLayout ? styles.fourColumnLayout : '',
                    ].join(' ')}
                    key={estate.uuid}
                    width={[
                      1,
                      null,
                      null,
                      fourColumnLayout
                        ? 1 / 4
                        : twoColumnLayout || doubleLayout
                        ? 1 / 2
                        : 1,
                    ]}
                    flex={
                      doubleLayout || twoColumnLayout || fourColumnLayout
                        ? 'none'
                        : 1
                    }
                    flexDirection={
                      singleLayout ? ['column', null, null, 'row'] : 'column'
                    }
                  >
                    {image && (
                      <Box
                        className={styles.image}
                        width={[1, null, null, 1 / 2]}
                      >
                        <Image src={image} size={'50vw'} alt={estate.name} />
                      </Box>
                    )}
                    <Link
                      className={styles.estateLink}
                      title={estate.name}
                      to={`/${estate.full_slug}`}
                    >
                      {estate.name && (
                        <Box className={styles.name}>
                          <Box className={styles.text} paddingRight={[2]}>
                            {estate.name}
                          </Box>
                          <ArrowRightIcon className={styles.icon} />
                        </Box>
                      )}
                      {tables &&
                        tables.length > 0 &&
                        tables.map((table) => <Table blok={table} />)}
                    </Link>
                  </Column>
                )
              })}
            </Row>
          </Box>
        </Flex>
      </Container>
    </SbEditable>
  ) : null
}

export default RealEstates
