import React, { useEffect, useState } from 'react'
import { useObserver } from 'mobx-react'
import { parseISO } from 'date-fns'

import useStore from 'src/stores/useStore'
import RecentPosts from 'src/components/RecentPosts/RecentPosts'

// import * as styles from './TriplePosts.module.scss'
import { Props } from './types'

const TriplePosts = ({ blok }: Props) => {
  const {
    news_title,
    news_link,
    news_list,
    press_title,
    press_link,
    press_releases,
    events_title,
    events_link,
    event_list,
  } = blok
  const upcomingEvents = event_list?.posts?.length > 0 ? event_list.posts : []
  const [events, setEvents] = useState(upcomingEvents)
  const { press } = useStore()

  useEffect(() => {
    // Update events if dates have passed from todays date
    const today = new Date()
    const eventsFromToday = upcomingEvents.filter((event: any) => {
      return parseISO(event.date) >= today
    })

    if (eventsFromToday?.length < upcomingEvents?.length) {
      setEvents(eventsFromToday)
    }

    if (press.posts.length < 1) {
      press.fetchNextPosts()
    }
  }, [setEvents])

  return useObserver(() => {
    // Recent posts data
    const recentPosts = []
    const pressReleases =
      press?.entries && press?.entries.length > 0
        ? press?.entries
        : press_releases?.posts?.length > 0
        ? press_releases.posts.slice(0, 1)
        : []

    if (news_list) {
      recentPosts.push({
        type: 'news',
        posts: news_list?.posts?.length > 0 ? news_list.posts.slice(0, 1) : [],
        title: news_title,
        url: news_link,
        linkType: 'internal',
      })
    }
    if (pressReleases && pressReleases.length > 0) {
      recentPosts.push({
        type: 'press',
        posts: pressReleases?.length > 0 ? pressReleases.slice(0, 1) : [],
        title: press_title,
        url: press_link,
        linkType: 'external',
      })
    }
    if (event_list) {
      recentPosts.push({
        posts: events?.length > 0 ? events.slice(0, 1) : [],
        type: 'events',
        title: events_title,
        url: events_link,
        linkType: 'external',
      })
    }

    return <RecentPosts startGrid sections={recentPosts} />
  })
}

export default TriplePosts
