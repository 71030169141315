import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import axios from 'axios'

import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'

import * as styles from './Newsletter.module.scss'
import { Props } from './types'
import { useTranslation } from 'react-i18next'

const Newsletter = ({ blok }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(0)
  const { t } = useTranslation()
  // STATUS
  // 0 = Not posted
  // 1 = Success
  // 2 = Went wrong

  const onSubmit = async (e: any) => {
    if (status !== 0) {
      return false
    }
    const url = 'https://publish.ne.cision.com/Subscription/Subscribe'
    e.preventDefault()
    const formData = new FormData(e.target)
    try {
      setIsLoading(true)
      const response = await axios({
        url: url,
        method: 'POST',
        data: formData,
      })
      setIsLoading(false)
      if (response && response.status && response.status === 200) {
        setStatus(1)
      } else {
        setStatus(2)
      }
    } catch (error) {
      setIsLoading(false)
      setStatus(2)
    }
  }

  const { title, description } = blok
  const newsletter_id = '5ba86cd777'
  return (
    <SbEditable content={blok}>
      <Container className={styles.wrapper} paddingY={[18, null, null, 35]}>
        <Row justifyContent="space-between" flexWrap="nowrap">
          <Column
            marginBottom={[25, null, null, 0]}
            width={[1, null, null, 4 / 10]}
            marginLeft={[0, null, null, `${(1 / 12) * 100}%`]}
          >
            <Box marginBottom={6} as="h3">
              {title}
            </Box>
            <Box as="p">{description}</Box>
          </Column>
          <Column
            width={[1, null, null, 5 / 10]}
            marginRight={[0, null, null, `${(1 / 12) * 100}%`]}
          >
            <form onSubmit={onSubmit} className={styles.form}>
              <input
                type="hidden"
                name="subscriptionUniqueIdentifier"
                value={newsletter_id}
              />
              <input type="hidden" name="Replylanguage" value="sv" />
              <input
                onChange={() => {
                  if (status !== 0) {
                    setStatus(0)
                  }
                }}
                placeholder={t('name')}
                type="text"
                name="name"
              />
              <input
                onChange={() => {
                  if (status !== 0) {
                    setStatus(0)
                  }
                }}
                placeholder={t('company')}
                type="text"
                name="companyname"
              />
              <input
                onChange={() => {
                  if (status !== 0) {
                    setStatus(0)
                  }
                }}
                placeholder={t('eMail')}
                type="email"
                name="email"
              />
              <Flex alignItems="flex-end">
                {status === 1 && (
                  <Box as="p">Du är nu tillagd i vår prenumerationslista.</Box>
                )}
                {status === 2 && <Box as="p">Något gick fel. Försök igen!</Box>}
                <button>
                  {isLoading ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="22"
                      height="22"
                      className={styles.loader}
                      viewBox="0 0 100 100"
                    >
                      <path
                        fill="white"
                        d="M50.6,95c-14.3,0-27.9-6.9-36.4-18.5l14.5-10.6C33.9,72.9,42,77,50.6,77c14.9,0,27-12.1,27-27  s-12.1-27-27-27l-0.1,0V5v9l0-9c24.9,0,45.1,20.2,45.1,45S75.4,95,50.6,95z"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.1781 0.25867L10.1598 1.27698L19.0831 10.2003L0.640015 10.2003L0.640016 11.6405L19.0831 11.6405L10.1598 20.5638L11.1781 21.5821L21.8398 10.9204L11.1781 0.25867Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </button>
              </Flex>
            </form>
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default Newsletter
