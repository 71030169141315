import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Container from 'src/components/UI/Grid/Container'
import Markdown from 'src/components/Markdown/Markdown'
import * as styles from './TextAndCta.module.scss'
import { Props } from './types'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { ArrowRightIcon } from 'src/components/UI/Elements/Icon'
import Image from 'src/components/UI/Image/Image'

const TextAndCta = ({ blok }: Props) => {
  const { text, cta_text, cta_slug, image } = blok
  return (
    <SbEditable content={blok}>
      <Flex
        flexDirection={['column', null, null, 'row']}
        className={styles.wrapper}
        width={1}
      >
        <Flex
          flexDirection="column"
          width={[1, null, null, image && image.filename ? 6 / 12 : 9 / 12]}
        >
          <Flex
            paddingY={
              image && image.filename
                ? [20, null, null, '174px']
                : [20, null, null, 22]
            }
            className={
              image && image.filename
                ? [styles.content, styles.light].join(' ')
                : styles.content
            }
            flexDirection="column"
            width={1}
          >
            <Container>
              <Markdown source={text} />
            </Container>
          </Flex>
          <LinkComponent className={styles.cta} to={cta_slug}>
            <Container>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width={1}
              >
                {cta_text}
                <ArrowRightIcon width={50} height={50} />
              </Flex>
            </Container>
          </LinkComponent>
        </Flex>
        {image && image.filename && (
          <Box
            className={styles.image}
            style={{ position: 'relative' }}
            width={[1, null, null, 1 / 2]}
          >
            <Image src={image.filename} size={'50vw'} alt={cta_text} />
          </Box>
        )}
      </Flex>
    </SbEditable>
  )
}

export default TextAndCta
