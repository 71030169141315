import React from 'react'
import SbEditable from 'storyblok-react'

import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Components from 'src/storyblok/Components'

import * as styles from './DownloadList.module.scss'
import { Props } from './types'

const DownloadList = ({ blok, noSPacing }: Props) => {
  const { sections } = blok
  return (
    <SbEditable content={blok}>
      <Container
        className={styles.wrapper}
        paddingTop={noSPacing ? 0 : [11, null, null, 16]}
        paddingBottom={[2, null, null, 2]}
      >
        <Row alignItems="center" flexDirection="column">
          {sections &&
            sections.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            )}
        </Row>
      </Container>
    </SbEditable>
  )
}

export default DownloadList
