import React from 'react'
import { Box, Flex } from 'reflexbox'
import Image from 'src/components/UI/Image/Image'
import SbEditable from 'storyblok-react'
import * as styles from './MaterialCard.module.scss'
import { Props } from './types'

const MaterialCard = ({ blok, index }: Props) => {
  const { image, title } = blok
  return (
    <SbEditable content={blok}>
      <Flex flexDirection="column" width={1}>
        <Image src={image.filename} alt={title} size="50vw" />
        <Flex className={styles.title} marginTop={[3, null, null, 4]}>
          {index && <Box as="span" width={1 / 4}>{`0${index}`}</Box>}
          <Box width={3 / 4} as="span">
            {title}
          </Box>
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default MaterialCard
