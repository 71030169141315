import React, { useEffect, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { useObserver } from 'mobx-react'
import { parseISO } from 'date-fns'

import RealEstatesMap from 'src/components/RealEstatesMap/RealEstatesMap'
import RealEstates from 'src/components/RealEstates/RealEstates'
import { getSeoFromHero } from 'src/helpers/getSeoFromHero'
import Markdown from 'src/components/Markdown/Markdown'
import SEO from 'src/components/SEO/SEO'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import Components from 'src/storyblok/Components'
import RecentPosts from 'src/components/RecentPosts/RecentPosts'
import Disclaimer from 'src/storyblok/GlobalModules/Disclaimer/Disclaimer'
import useStore from 'src/stores/useStore'

import * as styles from './PageInvestmentFund.module.scss'
import { Props } from './types'

const PageInvestmentFund = (props: Props) => {
  const { blok, preview } = props

  const {
    disclaimer,
    hero,
    preamble,
    description,
    content,
    video_section,
    link_section,
    info_section,
    text_section,
    file_downloads,
    newsletter,
    seo,
    event_list,
    press_releases,
    _uid,
  } = blok

  let { seo_description }: any = seo && seo.length > 0 ? seo[0] : {}
  const { seo_title, seo_image } = getSeoFromHero(seo, hero)
  if (!seo_description && preamble) {
    seo_description = preamble
  }

  const { press } = useStore()

  const upcomingEvents = event_list?.length > 0 ? event_list[0].posts : []
  const [events, setEvents] = useState(upcomingEvents)

  useEffect(() => {
    // Update events if dates have passed from todays date
    const today = new Date()
    const eventsFromToday =
      upcomingEvents?.length > 0
        ? upcomingEvents.filter((event: any) => {
            return parseISO(event.date) >= today
          })
        : []

    if (eventsFromToday?.length < upcomingEvents?.length) {
      setEvents(eventsFromToday)
    }
  }, [setEvents])

  const totalLinks =
    link_section &&
    link_section[0] &&
    link_section[0].links &&
    link_section[0].links.length

  let keyword = ''

  if (press_releases?.length > 0) {
    keyword = press_releases[0].cision_keyword
  }
  if (keyword && press?.keywordPosts) {
    if (
      press.keywordPosts[keyword] &&
      press.keywordPosts[keyword].fetched === false
    ) {
      press.fetchByKeyword(keyword)
    }
  }

  const hasHero =
    hero?.length > 0 && hero[0].component === 'hero' ? true : false

  return useObserver(() => {
    // Recent posts data
    const recentPosts = []
    const clientPosts = press.entriesByKey(keyword)
    if (clientPosts && clientPosts.length > 0) {
      recentPosts.push({
        posts: clientPosts,
        title: press_releases[0].title,
        url: press_releases[0].link ? press_releases[0].link : null,
        linkType: 'external',
      })
    } else if (press_releases?.length > 0) {
      recentPosts.push({
        posts: press_releases[0].posts,
        title: press_releases[0].title,
        url: press_releases[0].link ? press_releases[0].link : null,
        linkType: 'external',
      })
    }
    if (event_list?.length > 0) {
      recentPosts.push({
        posts: events,
        title: event_list[0].title,
        url: event_list[0].link ? event_list[0].link : null,
        linkType: 'download',
      })
    }

    return (
      <SbEditable content={blok}>
        {!preview && disclaimer && (
          <Disclaimer id={_uid} enabled={disclaimer} />
        )}
        <Flex width={1} flexDirection="column">
          <SEO
            title={seo_title}
            metaDescription={seo_description}
            image={seo_image}
            bodyClass={!hasHero ? 'dark-header' : ''}
          />
          {hero?.length > 0 &&
            hero.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            )}
          {(preamble || description) && (
            <Container>
              <Row paddingY={[20]}>
                <Column width={[1, null, null, 9 / 12]}>
                  {preamble && (
                    <Box className={styles.preamble} as="p">
                      {preamble}
                    </Box>
                  )}
                </Column>
                {description && (
                  <Column
                    marginTop={[8, null, null, 12]}
                    width={[1, null, null, 7 / 12]}
                    marginLeft={[0, null, null, `${(4 / 12) * 100}%`]}
                    justifyContent="flex-end"
                  >
                    <Markdown source={description} />
                  </Column>
                )}
              </Row>
            </Container>
          )}
          {content &&
            content.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            )}
          {video_section &&
            video_section.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            )}
          {link_section &&
            link_section.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            )}
          {info_section &&
            info_section.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
                imageLeft: totalLinks > 1,
              })
            )}
          {text_section &&
            text_section.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
                imageLeft: totalLinks > 1,
              })
            )}
          <RealEstates blok={blok} />
          <RealEstatesMap blok={blok} />
          {newsletter &&
            newsletter.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            )}
          {recentPosts?.length > 0 && <RecentPosts sections={recentPosts} />}
          {file_downloads &&
            file_downloads.map((blok: any) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
                noSPacing: true,
              })
            )}
        </Flex>
      </SbEditable>
    )
  })
}

export default PageInvestmentFund
